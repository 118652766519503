import React, { useEffect, useState } from "react";
import Phone from "./Phone";
import { TailSpin } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import config from "../../../config/configg";
import expertlistingAction from "../../../actions/expertlisting.action";
import { toast, ToastContainer } from "react-toastify";
const BACKEND_URL = config.BACKEND_URL;

const Audio = () => {
  const location = useLocation();
  const [expertIdentity, setExpertIdentity] = useState("");
  const [mainLoader, setMainLoader] = useState(true);
  const [expertId, setExpertId] = useState();
  const [isRated, setIsRated] = useState(false);
  const [token, setToken] = useState("");
  const handleClick = async () => {
    // setClicked(true);
    const data = await fetch(`${BACKEND_URL}/website/twlioVoiceCallUser`, {
      method: "POST",
      body: JSON.stringify({
        appointmentId: location.state.appointmentId,
      }),
      headers: {
        "Content-Type": "application/json",
        authorization: localStorage.getItem("token"),
      },
    }).then((res) => res.json());
    setToken(data.data.token);
    setExpertIdentity(data.data.expertIdentity);
    setExpertId(data.data.expertId);
    // .then(({ token }) => setToken(token));
  };
  useEffect(() => {
    handleClick();
    isExpertAlreadyRated();
  }, []);
  const isExpertAlreadyRated = () => {
    let dataToSend = {
      // filterType:filterType,
      expertId: expertId,
    };
    expertlistingAction.isExpertRated(dataToSend, (err, res) => {
      if (err) {
        // console.log(err," appointments error")
        toast(res.message);
      } else {
        // toast('Thanks for rating our expert')

        setIsRated(res.data.isRated);
      }
      setMainLoader(false);
    });
  };
  return (
    <div>
      <ToastContainer />
      {token == "" ? (
        <div className="custm-loader">
          <TailSpin color="#000" height={200} width={200} />
        </div>
      ) : (
        <Phone
          token={token}
          expertIdentity={expertIdentity}
          expertId={expertId}
          setToken={setToken}
          isRated={isRated}
          appointmentId={location.state.appointmentId}
        />
      )}
    </div>
  );
};

export default Audio;
