import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "react-js-loader";
import Room from "./Room";
import "./convo/Video.css";
import ConversationsApp from "./ConversationApp";
import { prependOnceListener } from "superagent";
import config from "../../../config/configg";
import { TailSpin } from "react-loader-spinner";
import expertlistingAction from "../../../actions/expertlisting.action";
import { toast } from "react-toastify";
import Video from "twilio-video";

const BACKEND_URL = config.BACKEND_URL;

const VideoChat = (props) => {
  const [mainLoader, setMainLoader] = useState(true);
  const [roomName, setRoomName] = useState("");
  const [identity, setIdentity] = useState("");
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [convoId, setConvoId] = useState("");
  const [expertId, setExpertId] = useState(0);
  const [isRated, setIsRated] = useState(false);

  const [room, setRoom] = useState(null);

  const location = useLocation();
  const history = useNavigate();

  const handleSubmit = useCallback(async () => {
    try {
      setMainLoader(true);
      const data = await fetch(`${BACKEND_URL}/website/videoChatTokenUser`, {
        method: "POST",
        body: JSON.stringify({
          appointmentId: location.state.appointmentId,
        }),
        headers: {
          "Content-Type": "application/json",
          authorization: localStorage.getItem("token"),
        },
      }).then((res) => res.json());
  
      console.log("data from API", data)
      setMainLoader(false);
      setToken(data.data?.token);
      setRoomName(data.data?.roomId);
      setIdentity(data.data?.identity);
      setExpertId(data.data?.expertId);
  
      let roomObj = await Video.connect(data.data.token, { uniqueName: data.data.roomId });
      console.log("roomObj", roomObj)
      setRoom(roomObj);
    } catch(err) {
      console.log("[Error connecting room]", err)
    } 
  }, [roomName]);

  useEffect(() => {
    isExpertAlreadyRated();
    handleSubmit();
  }, []);

  const handleLogout = useCallback((event) => {
    setToken(null);
    setTimeout(() => history("/appointmentdetails"), 100);
  }, []);

  const isExpertAlreadyRated = () => {
    let dataToSend = {
      // filterType:filterType,
      expertId: expertId,
    };
    expertlistingAction.isExpertRated(dataToSend, (err, res) => {
      if (err) {
        // console.log(err," appointments error")
        toast(res.message);
      } else {
        // toast('Thanks for rating our expert')

        setIsRated(res.data.isRated);
      }
      setMainLoader(false);
    });
  };

  const handleRoomDisconnect = useCallback(() => {
    console.log("Handle Room Disconnect");
    setRoom((prevRoom) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      handleLogout()
      return null;
    });
  }, []);

  let render;
  if (token) {
    render = (
      <div className="row" style={{ marginTop: "95px" }}>
        <div className="col-lg-8">
          {room ? (
            <Room
              roomName={roomName}
              token={token}
              handleLogout={handleLogout}
              expertId={expertId}
              isRated={isRated}
              appointmentId={location.state.appointmentId}
              room={room}
              handleRoomDisconnect={handleRoomDisconnect}
            />
          ) : null}
        </div>
        <div className="col-lg-4">
          <ConversationsApp
            token={token}
            name={identity}
            room={roomName}
            setToken={setToken}
          />
        </div>
      </div>
    );
  } else {
    render = (
      <>
        {mainLoader ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : null}
      </>
    );
  }
  return render;
};

export default VideoChat;
