import React, { useState,useEffect } from "react";
import Footer from "./Footer";
import NewsletterSubscribed from "./NewsletterSubscribed";
import { validatePhoneNo } from "../../utils/helpers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import homeAction from "../../actions/home.action";
import { TailSpin } from 'react-loader-spinner';

const ContactUs = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [firmName, setFirmName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [mainLoader, setLoader] = useState(false);

  
  
    
  const addUserRequest = async () => {
    let validatePhoneNoData = await validatePhoneNo(phoneNo);
    if (validatePhoneNoData) {
      setLoader(true)
      let dataToSend = { firstName, lastName, email, firmName, phoneNo };
      await homeAction.addUserRequest(dataToSend, (err, res) => {
        if (err) {
          toast(err.message);
          setLoader(false)

        } else {
          setFirstName("");
          setLastName("");
          setEmail("");
          setPhoneNo("");
          setFirmName("");

          toast("Success! An acknowledgement mail has been sent to you.");
          setLoader(false)

        }
      });
    } else {
      toast("Phone number is not valid");
    }
  };

  useEffect(()=>{
    window.scrollTo({ top: 0, behavior: "smooth" });
 })
  const handleChange = async (event) => {
    if (event.target.name === "firstName") {
      setFirstName(event.target.value);
    }
    if (event.target.name === "lastName") {
      setLastName(event.target.value);
    }
    if (event.target.name === "email") {
      setEmail(event.target.value);
    }
    if (event.target.name === "firmName") {
      setFirmName(event.target.value);
    }
    if (event.target.name === "phoneNo") {
      setPhoneNo(event.target.value);
    }
  };

  return (
    <>
      <section class="contact-us mt-5">
      {mainLoader ? (
            <div className="custm-loader">
               <TailSpin color="#000" height={200} width={200} />
            </div>
         ) : null}
        <div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-7 col-md-12">
              <h2>Contact Us</h2>
              <p class="contact-tagline text-muted">
                Looking to speak with a member of our sales team or a customer
                service representative? Contact MyCase today.
              </p>

              <div class="custom-form">
                <div class="mt-4 ">
                  <input
                    type="text"
                    class="form-control"
                    id="firstName"
                    placeholder="First Name"
                    name="firstName"
                    value={firstName}
                    onChange={handleChange}
                  />
                </div>
                <div class="mb-3 mt-4">
                  <input
                    type="text"
                    class="form-control"
                    id="lastName"
                    placeholder="Last Name"
                    name="lastName"
                    value={lastName}
                    onChange={handleChange}
                  />
                </div>
                <div class="mb-3 mt-4">
                  <input
                    type="email"
                    class="form-control"
                    id="email"
                    placeholder="Email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                  />
                </div>
                <div class="mb-3 mt-4">
                  <input
                    type="text"
                    class="form-control"
                    id="firmName"
                    placeholder="Firm Name"
                    name="firmName"
                    value={firmName}
                    onChange={handleChange}
                  />
                </div>
                <div class="mb-3 mt-4">
                  <input
                    type="text"
                    class="form-control"
                    id="phoneNo"
                    placeholder="Phone Number"
                    name="phoneNo"
                    value={phoneNo}
                    onChange={handleChange}
                  />
                </div>
                <div class="mb-3 mt-4">
                  <button
                    type="submit"
                    class="btn contact-me"
                    onClick={() => {
                      addUserRequest();
                    }}
                  >
                    Contact Me
                  </button>
                </div>
              </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-12">
              <img
                src="/assets/img/Contact us-rafiki.png"
                class="img-fluid contact-form-img "
              />
            </div>
          </div>
        </div>
        <div class="container more-contact-details">
          <div class="row">
            <div class="col-lg-3 col-md-6 mt-2">
              <div class="contact-details-inner">
                <h5>Borhan</h5>
                <ul class="pt-3 list-address">
                  <li>
                    <p class="text-secondary">9201 Spectrum Center Blvd., </p>
                  </li>
                  <li>
                    <p class="text-secondary">Suite 100 San Diego, CA 92123</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-2">
              <div class=" contact-details-inner">
                <h5>Send Us A Message</h5>
                <ul class="pt-3 list-address">
                  <li>
                    <p class="text-primary">Sale@borhan.com</p>
                  </li>
                  <li>
                    <p class="text-primary">Support@borhan.com</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-2">
              <div class=" contact-details-inner">
                <h5>Give Us A Call</h5>
                <ul class="pt-3 list-address">
                  <li>
                    <p>
                      <a href="tel:181787888799" class="text-primary">
                        181-7878887-99
                      </a>
                    </p>
                  </li>
                  <li>
                    <p class="text-secondary">Monday-Friday</p>
                  </li>
                  <li>
                    <p class="text-secondary">6AM-5PM Pacific Time</p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 mt-2">
              <div class="contact-details-inner">
                <h5 class="text-center">Download Borhan app</h5>
                <ul class="pt-3 list-address text-center">
                  <li>
                    <a href="">
                      <img src="/assets/img/app-store-btn.png" />
                    </a>
                  </li>
                  <li class="mt-2">
                    <a href="">
                      <img src="/assets/img/google-play-store-btn.png" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <NewsletterSubscribed />
      <Footer />
    </>
  );
};

export default ContactUs;
