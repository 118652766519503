import "./App.css";
import "./styles/main.css";
import FavExp from "./components/Frontend/FavExperts";
import AppointmentDetails from "./components/Frontend/AppointmentDetails";
import ErrorPage from "./components/Frontend/ErrorPage";
import ExpListing from "./components/Frontend/ExpListing";
import ExpProfile from "./components/Frontend/ExpProfile";
import Home from "./components/Frontend/Home";
import Wallet from "./components/Frontend/Wallet";
import SavedCards from "./components/Frontend/SavedCards";
import UserDashBoard from "./components/Frontend/UserDashBoard";
import Terms from "./components/Frontend/Terms";
import Privacy from "./components/Frontend/Privacy";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Frontend/Header";
import ChatSupport from "./components/Frontend/ChatSupport";
import Membership from "./components/Frontend/Membership";
import ContactUs from "./components/Frontend/ContactUs";
import PremiumExpert from "./components/Frontend/PremiumExpert";
import ManageMembership from "./components/Frontend/ManageMembership";
import PaymentSuccess from "./components/Frontend/PaymentSuccess";
import PaymentCompleted from "./components/Frontend/PaymentCompleted";
import PaymentCanceled from "./components/Frontend/PaymentCanceled";
import AboutUs from "./components/Frontend/AboutUs";
import { AuthContext } from "./context/AuthContext";
import { useContext, useEffect } from "react";
import homeAction from "./actions/home.action";
import CategoryAndPracticeContextProvider from "./context/CategoryAndPracticeContext";
import Room from "./components/Frontend/ChatRoom";
import "./assets/css/bootstrap.min.css";
import "./assets/css/main.css";
import "./assets/css/responsive.css";
import VideoChat from "./components/Frontend/VideoChat/VideoChat";
import Audio from "./components/Frontend/Audio/Audio";
import PageNotFound from "./components/Frontend/PageNotFound";

import { TailSpin } from "react-loader-spinner";

function App() {
  const {
    isLoggedIn,
    setIsLoggedIn,
    loggedInBorhanUserInfo,
    setLoggedInBorhanUserInfo,
  } = useContext(AuthContext);
  useEffect(() => {
    if (localStorage.getItem("token") !== null) {
      homeAction.getBorhanUserDetails((err, res) => {
        if (err) {
          setIsLoggedIn(false);
          localStorage.removeItem("token");
        } else {
          setIsLoggedIn(true);
          setLoggedInBorhanUserInfo(res.data);
        }
      });
    }
  }, [isLoggedIn]);

  return (
    <>
      <CategoryAndPracticeContextProvider>
        <Router>
          <Header />
          <Routes>
            {isLoggedIn === true ? (
              <>
                <Route exact path="/room/:id" key="room" element={<Room />} />

                <Route
                  exact
                  path="/ChatSupport"
                  key="ChatSupport"
                  element={<ChatSupport />}
                />

                <Route
                  path="/expprofile/:id/:category/:practiceArea"
                  key="expprofile"
                  element={<ExpProfile />}
                />

                <Route path="/wallet" key="wallet" element={<Wallet />} />
                <Route
                  path="/savedCards"
                  key="wallet"
                  element={<SavedCards />}
                />
                <Route
                  path="/userdashboard"
                  key="userdashboard"
                  element={<UserDashBoard />}
                />
                <Route
                  path="/appointmentdetails"
                  key="appointmentdetails"
                  element={<AppointmentDetails />}
                />
                <Route
                  path="/managemembership"
                  key="managemembership"
                  element={<ManageMembership />}
                />
                <Route
                  path="/videochat"
                  key="videochat"
                  element={<VideoChat />}
                />
                <Route path="/audiocall" key="audiocall" element={<Audio />} />
              </>
            ) : (
              <>
                <Route
                  exact
                  path="/expertlistings/:id"
                  key="expertlisting"
                  element={<ExpListing />}
                />

                <Route exact path="/room" key="room" element={<Room />} />
              </>
            )}
            <Route path="/favexperts" key="favexperts" element={<FavExp />} />
            <Route
              path="/membership"
              key="membership"
              element={<Membership />}
            />
            <Route
              exact
              path="/expertlisting"
              key="expertlisting"
              element={<ExpListing />}
            />
            <Route path="/contactus" key="contactus" element={<ContactUs />} />
            <Route
              path="/premiumexpert"
              key="premiumexpert"
              element={<PremiumExpert />}
            />
            <Route
              exact
              path="/privacypolicy"
              key="privacy"
              element={<Privacy />}
            />

            <Route
              exact
              path="/termsandconditions"
              key="termsandconditions"
              element={<Terms />}
            />
            <Route exact path="/" key="home" element={<Home />} />
            
            <Route path="/errorpage" key="errorpage" element={<ErrorPage />} />
            <Route path="/aboutUs" key="aboutUs" element={<AboutUs />} />
            <Route path="*" element={<PageNotFound />} />
            <Route
              path="/paymentCanceled/:transactionId/:paymentType/:userId"
              key="paymentCanceled"
              element={<PaymentCanceled />}
            />
            <Route
              path="/paymentSuccess/:transactionId/:paymentType/:userId"
              key="paymentSuccess"
              element={<PaymentSuccess />}
            />
            <Route
              path="/paymentCompleted"
              key="paymentCompleted"
              element={<PaymentCompleted />}
            />
          </Routes>
        </Router>
      </CategoryAndPracticeContextProvider>
    </>
  );
}

export default App;
