import { React, useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import homeAction from "../../actions/home.action";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const params = useParams();
  useEffect(() => {
    updatePaymentStatus();
  }, []);

  const updatePaymentStatus = async () => {
    let dataToSend = {
      transactionId: params.transactionId,
      paymentType: params.paymentType,
      userId: params.userId,
    };
    homeAction.updateUserSubscription(dataToSend, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        console.log("res", res.data);
        navigate('/paymentCompleted');
      }
    });
  };
  return (
    <>
      <section class="page-fourzero-banner success-bg">
        <div class="container"></div>
      </section>
      <section class="fourzero-content">
        <div class="container">
          <h4>Payment Success</h4>
          {/* <p>
            You didn't break the internet but we can't find what are you looking
            for
          </p> */}
          {/* <div class="gohome">
            <a href="/" class="btn btn-outline" type="button">
              Return Home
            </a>
          </div> */}
        </div>
      </section>
    </>
  );
};
export default PaymentSuccess;
