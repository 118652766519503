const faceBookLink="https://www.facebook.com/";
const instagramLink="https://www.instagram.com/accounts/login/";
const linkDinLink="https://www.linkedin.com";
const twitterLink="https://twitter.com";
const appPlayStoreLink="https://play.google.com";
const iosStoreLink="https://www.apple.com";


export default {
    faceBookLink,
    instagramLink,
    linkDinLink,
    twitterLink,
    appPlayStoreLink,
    iosStoreLink,

}