const settings = {
  // BACKEND_URL: "https://borhanbackends.herokuapp.com",
  BACKEND_URL:"https://api.borhans.com",
  // BACKEND_URL: "http://localhost:5000",


  LEGAL_URL: "https://legal.borhans.com",
  // LEGAL_URL: "http://localhost:3000",
};
// 7859431623

export default settings;
