import React, { useState, useEffect } from "react";
import Video from "twilio-video";
import Participant from "./Participant";
import "./convo/Video.css";
import expertlistingAction from "../../../actions/expertlisting.action";
import StarRatings from "react-star-ratings";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";

import { toast, ToastContainer } from "react-toastify";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Countdown from "react-countdown";
import appointmentAction from "../../../actions/appointment.action";

const Room = ({
  roomName,
  token,
  handleLogout,
  expertId,
  isRated,
  appointmentId,
  setToken,
  room,
  handleRoomDisconnect
}) => {

  const [remoteParticipants, setRemoteParticipants] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [ratingModal, setRatingModal] = useState(false);
  const [expertRating, setExpertRating] = useState(0);
  const [mainLoader, setMainLoader] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [redirectModal, setRedirectModal] = useState(false);

  useEffect(() => {
    GetSingleAppointment();
  }, [roomName, token]);

  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant) => {
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    const roomDisconnected = (room, error) => {
      handleRoomDisconnect();
    }

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.on('disconnected', roomDisconnected);
    room.participants.forEach(participantConnected);

    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
      room.off('disconnected', roomDisconnected);
    };
  }, [room]);

  const handleRatingExpert = () => {
    if (expertRating == 0) {
      toast("Please rate the expert");
      return;
    }
    let dataToSend = {
      expertId: expertId,
      rating: expertRating,
    };
    expertlistingAction.rateExpert(dataToSend, (err, res) => {
      if (err) {
        toast(res.message);
      } else {
        toast("Thanks for rating our expert");
        handleLogout();
      }
      setMainLoader(false);
    });
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setRedirectModal(true);
      if (isRated) {
        return (
          <Modal
            isOpen={redirectModal}
            className="  modal-dialog modal-dialog-centered "
          >
            <ModalHeader>
              <h5 class="modal-title" id="exampleModalLabel">
                Thanks for joining! Click Confirm to get redirected{" "}
              </h5>
            </ModalHeader>
            <ModalBody></ModalBody>
            <ModalFooter>
              <button
                type="button"
                class="btn btn-dark"
                onClick={() => handleLogout()}
              >
                Confirm
              </button>
            </ModalFooter>
          </Modal>
        );
      } else {
        return (
          <Modal
            isOpen={redirectModal}
            className="  modal-dialog modal-dialog-centered "
          >
            <ModalHeader>
              <h5 class="modal-title" id="exampleModalLabel">
                Thanks for joining! Rate our expert{" "}
              </h5>
            </ModalHeader>
            <ModalBody>
              <StarRatings
                rating={expertRating}
                starRatedColor={"yellow"}
                numberOfStars={5}
                starDimension="20px"
                changeRating={(e) => {
                  setExpertRating(e);
                  console.log(expertRating, "rating", e);
                }}
                starSpacing="2px"
              />
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                class="btn btn-dark"
                onClick={() => handleRatingExpert()}
              >
                Confirm
              </button>
            </ModalFooter>
          </Modal>
        );
      }
    } else {
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const GetSingleAppointment = async () => {

    let dataToSend = {
      appointmentId: appointmentId,
    };
    appointmentAction.getSingleAppointment(dataToSend, (err, res) => {
      if (err) {
        console.log(err, " appointments error");
      } else {
        setAppointmentDetails(res?.data?.appointmentDetails);
      }
    });
  };

  const displayUsers = async () => {
    let arryTemp = [];
    let remotePart = participants.map((participant, index) => {
      if (arryTemp.includes(participant.sid)) {
        return null
      } else {
        arryTemp.push(participant.sid);
        //  console.log(participant.sid,"ayush meena")
        return <Participant key={participant.sid} participant={participant} />;
      }
    });

    setRemoteParticipants(remotePart);
  };

  useEffect(() => {
    displayUsers();
    console.log("All the participants are", participants)
  }, [participants]);

  return (
    <>
      <ToastContainer />
      {mainLoader ? (
        <div className="custm-loader">
          <TailSpin color="#000" height={200} width={200} />
        </div>
      ) : null}
      <div className="room">
        <div>
          <br />
          <div class="new-join">
            <div>
              <h2 className="room-detail">Borhan VideoChat</h2>
            </div>
            <div>
              {
                <button className="btn custom-btn mr-2">
                  <Countdown
                    date={new Date(appointmentDetails?.endAppointmentTime)}
                    renderer={renderer}
                  >
                    {/* <Completionist /> */}
                  </Countdown>
                </button>
              }
              <button
                className="custom-btn mr-0"
                onClick={() => {
                  setRatingModal(true);
                }}
              >
                Leave Meeting
              </button>
            </div>
          </div>
        </div>
        <br />
        <h3 className="text-center"> Participants</h3>

        <div className="remote-participants">
          <div className="local-participant">
            {room ? (
              <Participant
                key={room.localParticipant.sid}
                participant={room.localParticipant}
              />
            ) : (
              ""
            )}
            {remoteParticipants}
          </div>
        </div>

        {isRated ? (
          <Modal
            isOpen={ratingModal}
            toggle={() => {
              setRatingModal(false);
            }}
            className="  modal-dialog modal-dialog-centered "
          >
            <ModalHeader>
              <h5 class="modal-title" id="exampleModalLabel">
                Are you sure you want to leave{" "}
              </h5>
            </ModalHeader>
            <ModalBody></ModalBody>
            <ModalFooter>
              {/* <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button> */}
              <button
                type="button"
                class="btn btn-dark"
                onClick={() => handleRoomDisconnect()}
              >
                Confirm
              </button>
            </ModalFooter>
          </Modal>
        ) : (
          <Modal
            isOpen={ratingModal}
            toggle={() => setRatingModal(false)}
            className="  modal-dialog modal-dialog-centered "
          >
            <ModalHeader>
              <h5 class="modal-title" id="exampleModalLabel">
                Rate our expert{" "}
              </h5>
            </ModalHeader>
            <ModalBody>
              <StarRatings
                rating={expertRating}
                starRatedColor={"yellow"}
                numberOfStars={5}
                starDimension="20px"
                changeRating={(e) => {
                  setExpertRating(e);
                  console.log(expertRating, "rating", e);
                }}
                starSpacing="2px"
              />
            </ModalBody>
            <ModalFooter>
              {/* <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button> */}
              <button
                type="button"
                class="btn btn-dark"
                onClick={() => handleRatingExpert()}
              >
                Confirm
              </button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    </>
  );
};
export default Room;
