import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import expertlistingAction from "../../actions/expertlisting.action";
import homeAction from "../../actions/home.action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

const Membership = () => {
  const navigate = useNavigate();
  const [question, SetQuestion] = useState([]);

  const [allPlans, setAllPlans] = useState([]);
  const [mainLoader, setMainLoader] = useState(true);
  useEffect(() => {
    fecth();
    fetchAllSubscriptionTypes();
  }, []);

  const fetchAllSubscriptionTypes = async () => {
    homeAction.getAllSubscriptionTypes((err, res) => {
      if (err) {
        console.log(err);
      } else {
        if (res.data.length > 0) {
          setAllPlans(res.data);
          setMainLoader(false)
        }
      }
    });
  };
  const fecth = () => {
    expertlistingAction.getQuesAndAns((err, res) => {
      if (err) {
      } else {
        // console.log(res);
        SetQuestion(res.data);
      }
    });
  };

  const addSubscription = (id) => {
    if (localStorage.getItem("token")) {
      setMainLoader(true)
      let dataToSend = {
        subscriptionId: id,
        
      };
      homeAction.addUserSubscription(dataToSend, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          window.location.replace(res.data);

        }
      });
    } else {
      toast("Please Login First");
    }
  };
  return (
    <>
      <section class="membership-plan-wrp">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="common-head text-center">
                <h3>Choose the right plan for your firm</h3>
                <h4 class="text-muted mt-4">
                  Law Practice Management Software
                </h4>
              </div>
            </div>
          </div>
          {mainLoader ? (
            <div className="custm-loader-legal-practice">
              <TailSpin color="#000" height={200} width={200} />
            </div>
          ) : (
            <div class="row mt-5">
              {allPlans &&
                allPlans.length > 0 &&
                allPlans.map((el, i) => {
                  return (
                    <div class="col-lg-4" key={i}>
                      {el.subscriptionFor === "borhanUser" ? (<div class="membership-plan-box">
                        {el.planName === "premium" ? (
                          <div class="bv-tag">Best Value</div>
                        ) : (
                          ""
                        )}

                        <div class="side-indicator"></div>
                        <h3>
                          {el.planName.charAt(0).toUpperCase() +
                            el.planName.slice(1)}
                        </h3>
                        <h5>
                          <img
                            src="/assets/img/OMR-512.webp"
                            class="img img-fluid"
                            alt=""
                          />{" "}
                          {el.walletAmount}/{el.planDuration} Months
                        </h5>
                        <h4>
                          {" "}
                          <span></span> {el.planAmount} OMR
                        </h4>
                        <h4>
                          <b>{el.planAmount - el.discountValue} OMR</b>
                        </h4>

                        <button
                          class="btn"
                          type="button"
                          onClick={() => {
                            addSubscription(el._id);
                          }}
                        >
                          Buy{" "}
                          {el.planName.charAt(0).toUpperCase() +
                            el.planName.slice(1)}
                        </button>
                      </div>) : ("")}

                    </div>
                  );
                })}
            </div>
          )}

        </div>
        <div class="mem-plan-layer">
          <img
            src="/assets/img/membership-plan-bg.png"
            class="img img-fluid"
            alt=""
          />
        </div>
      </section>
      <section class="membership-plan-detail mt-5">
        <div class="container">
          <div class="common-head text-center ">
            <h3>Compare Plans</h3>
          </div>
          <div class="row mt-5">
            <div class="col-xl-12">
              <table class="table table-borderless membership-plans-table">
                <thead>
                  <tr class="border-bottom ">
                    <th>
                      <p>Key Features</p>
                    </th>
                    <th>
                      <p>Basic</p>
                    </th>
                    <th>
                      <p>Premium</p>
                    </th>
                    <th>
                      <p>Advance</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="membership-table-row">
                    <td>
                      <p>Case Management</p>
                    </td>
                    <td>
                      <img src="/assets/img/check.png" class="check-img" />
                    </td>
                    <td>
                      <img src="/assets/img/check.png" class="check-img" />
                    </td>
                    <td>
                      <img src="/assetsimg/check.png" class="check-img" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Case Management</p>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <img src="/assets/img/check.png" class="check-img" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Case Management</p>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <img src="/assets/img/check.png" class="check-img" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Case Management</p>
                    </td>
                    <td></td>
                    <td>
                      <img src="/assets/img/check.png" class="check-img" />
                    </td>
                    <td>
                      <img src="/assets/img/check.png" class="check-img" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Case Management</p>
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <img src="/assets/img/check.png" class="check-img" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p>Case Management</p>
                    </td>
                    <td></td>
                    <td>
                      <img src="/assets/img/check.png" class="check-img" />
                    </td>
                    <td>
                      <img src="/assets/img/check.png" class="check-img" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section class="faqs mt-5">
        <div class="container mt-5">
          <div class="common-head text-center ">
            <h3>Frequently Asked Questions</h3>
          </div>
          <div class="col-lg-12 mt-5">
            {question.length > 0
              ? question.map((e, index) => {
                return (
                  <>
                    <h4>{e.question}</h4>
                    <p>{e.answer}</p>
                  </>
                );
              })
              : ""}
          </div>
        </div>
      </section>
      <section class="newsletter-wrp mt-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="newsletter-feild-box">
                <form>
                  <div class="position-relative">
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Enter your email address....."
                    />
                    <button class="btn" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="newsletter-content">
                <div class="">
                  <h1>Newsletter</h1>
                  <p>
                    Be the first to know about exciting new offers and special
                    events and much more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Membership;
