import React, { useState } from 'react'

const FetchCategoriesList = ({selectedCategory,setSelectedCategory,getCategories,setGetCategories}) => {
  // const [categorieId,setCategorieId]=useState("")
  return (
      <><select
      class="form-control"
      onChange={(e) => {
        // console.log('the changes',e.target.value)
        // console.log('the changes',selectedCategory)
        // setCategorieId(e.target.value)
        // if(e.target.value!==)
        if(e.target.value !== selectedCategory) setSelectedCategory(e.target.value)
      }}
      value={selectedCategory}
    >
      <option selected value="">
        Filter Categories
      </option>
      
      {getCategories &&
        getCategories.map((obj, index) => {
          return (
            <option value={`${obj._id}`}>
              {obj.name}
            </option>
          );
        })}
    </select></>
  )
}

export default FetchCategoriesList