import React, { useState, useEffect } from "react";
import { Device } from "twilio-client";
import Dialler from "./Dialler";
import KeypadButton from "./KeypadButton";
import Incoming from "./Incoming";
import OnCall from "./OnCall";
import "./Phone.css";
import states from "./states";
import FakeState from "./FakeState";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import StarRatings from "react-star-ratings";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import expertlistingAction from "../../../actions/expertlisting.action";
import { TailSpin } from "react-loader-spinner";
import Countdown from "react-countdown";
import appointmentAction from "../../../actions/appointment.action";
const Phone = ({ token, expertIdentity, expertId, setToken, isRated, appointmentId }) => {
  const history = useNavigate();
  const [state, setState] = useState(states.CONNECTING);
  const [number, setNumber] = useState("");
  const [conn, setConn] = useState(null);
  const [device, setDevice] = useState(null);
  const [ratingModal, setRatingModal] = useState(false);
  const [expertRating, setExpertRating] = useState(0);
  const [mainLoader, setMainLoader] = useState(false);
  const [appointmentDetails, setAppointmentDetails] = useState(null);
  const [redirectModal, setRedirectModal] = useState(false);
  useEffect(() => {
    GetSingleAppointment();
    const device = new Device();

    device.setup(token, { debug: true });

    device.on("ready", () => {
      setDevice(device);
      setState(states.READY);
    });
    device.on("connect", connection => {
      console.log("Connect event");
      setConn(connection);
      setState(states.ON_CALL);
    });
    device.on("disconnect", () => {
      setState(states.READY);
      setConn(null);
    });
    device.on("incoming", connection => {
      setState(states.INCOMING);
      setConn(connection);
      connection.on("reject", () => {
        setState(states.READY);
        setConn(null);
      });
    });
    device.on("cancel", () => {
      setState(states.READY);
      setConn(null);
    });
    device.on("reject", () => {
      setState(states.READY);
      setConn(null);
    });

    return () => {
      device.destroy();
      setDevice(null);
      setState(states.OFFLINE);
    };
  }, [token]);

  const handleCall = () => {
    console.log("this is expertIdentity" , expertIdentity)
    device.connect({ To: expertIdentity });
    console.log("this is device" , device)
  };

  const handleHangup = () => {
    device.disconnectAll();
  };
  const handleRatingExpert = () => {

    if (expertRating == 0) {
      toast('Please rate the expert');
      return;
    }
    let dataToSend = {
      // filterType:filterType, 
      expertId: expertId,
      rating: expertRating,
    }
    expertlistingAction.rateExpert(dataToSend, (err, res) => {
      if (err) {
        // console.log(err," appointments error")
        toast(res.message);
      } else {
        toast('Thanks for rating our expert')
        // setPages(
        //   parseInt(res.data.count % sizePerPage) == 0
        //     ? parseInt(res.data.count / sizePerPage)
        //     : parseInt(res.data.count / sizePerPage + 1)
        // );
        setToken(null);
        // console.log(res.data," Apoointments are");
        setTimeout(() => {

          history('/appointmentdetails');
        }, 100)
      }
      setMainLoader(false);
    });


  }
  const handleLeave = () => {
    device.disconnectAll();
    toast('Thanks for joining');
    setTimeout(() => history('/appointmentdetails'), 500)

  }
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      setRedirectModal(true);
      if (isRated) {
        return (<Modal isOpen={redirectModal} className='  modal-dialog modal-dialog-centered '>


          <ModalHeader>
            <h5 class="modal-title" id="exampleModalLabel">Thanks for joining! Click Confirm to get redirected </h5>

          </ModalHeader>
          <ModalBody>
          </ModalBody>
          <ModalFooter>
            {/* <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button> */}
            <button type="button" class="btn btn-dark" onClick={() => handleLeave()}>Confirm</button>
          </ModalFooter>


        </Modal>)
      }

      else {
        return (<Modal isOpen={redirectModal} className='  modal-dialog modal-dialog-centered '>


          <ModalHeader>
            <h5 class="modal-title" id="exampleModalLabel">Thanks for joining! Rate our expert </h5>

          </ModalHeader>
          <ModalBody>
            <StarRatings
              rating={expertRating}
              starRatedColor={"yellow"}
              numberOfStars={5}
              starDimension="20px"
              changeRating={(e) => {
                setExpertRating(e);
                console.log(expertRating, 'rating', e);
              }}
              starSpacing="2px"
            />
          </ModalBody>
          <ModalFooter>
            {/* <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button> */}
            <button type="button" class="btn btn-dark" onClick={() => handleRatingExpert()}>Confirm</button>
          </ModalFooter>


        </Modal>)
      }
    }
    else {
      return <span>{days}:{hours}:{minutes}:{seconds}</span>;
    }
  }

  const GetSingleAppointment = async () => {

    let dataToSend = {
      // filterType:filterType, 
      // limit: sizePerPage,
      appointmentId: appointmentId,
    }
    appointmentAction.getSingleAppointment(dataToSend, (err, res) => {
      if (err) {
        console.log(err, " appointments error")
      } else {
        setAppointmentDetails(res?.data?.appointmentDetails);
        // let cdate=new Date(res?.data?.appointmentDetails?.endAppointmentTime);
        // let date=new Date();
        // let dif=cdate-date;
        // if(dif<=0)
        // {
        //   setCanComplete(true);
        // }
      }

    });

  };

  let render;
  if (conn) {
    if (state === states.INCOMING) {
      render = <Incoming device={device} connection={conn}></Incoming>;
    } else if (state === states.ON_CALL) {
      render = <OnCall handleHangup={handleHangup} connection={conn}></OnCall>;
    }
  } else {
    render = (
      <>
        {/* <Dialler number={number} setNumber={setNumber}></Dialler> */}
        <div className="call">
          {/* <input onChange={(e)=>setNumber(e.target.value)}/> */}
          <KeypadButton className="btn" handleClick={handleCall} color="green">
            Call
          </KeypadButton>
        </div>
      </>
    );
  }
  return (
    <>
      <ToastContainer />
      {mainLoader ? (
        <div className="custm-loader">
          <TailSpin color="#000" height={200} width={200} />
        </div>
      ) : null}

      <FakeState
        currentState={state}
        setState={setState}
        setConn={setConn}
      ></FakeState>
      {

        <button className='btn btn-dark '   ><Countdown date={new Date(appointmentDetails?.endAppointmentTime)}
          renderer={renderer}
        >
          {/* <Completionist /> */}
        </Countdown>
        </button>}
      <div className="call-wrap1">   {render}
        <p className="status">{state}</p>
        <button className="btn btn-light leave" onClick={() => setRatingModal(true)}>Leave</button>
      </div>

      {
        isRated ?
          <Modal isOpen={ratingModal} toggle={() => { setRatingModal(false) }} className='  modal-dialog modal-dialog-centered '>


            <ModalHeader>
              <h5 class="modal-title" id="exampleModalLabel">Are you sure you want to leave </h5>

            </ModalHeader>
            <ModalBody>
            </ModalBody>
            <ModalFooter>
              {/* <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button> */}
              <button type="button" class="btn btn-dark" onClick={() => handleLeave()}>Confirm</button>
            </ModalFooter>


          </Modal> :
          <Modal isOpen={ratingModal} toggle={() => { setRatingModal(false) }} className='  modal-dialog modal-dialog-centered '>


            <ModalHeader>
              <h5 class="modal-title" id="exampleModalLabel">Rate our expert </h5>

            </ModalHeader>
            <ModalBody>
              <StarRatings
                rating={expertRating}
                starRatedColor={"yellow"}
                numberOfStars={5}
                starDimension="20px"
                changeRating={(e) => {
                  setExpertRating(e);
                  console.log(expertRating, 'rating', e);
                }}
                starSpacing="2px"
              />
            </ModalBody>
            <ModalFooter>
              {/* <button type="button" class="btn btn-secondary" onClick={()=>{setDeleteModal(false);}}>Close</button> */}
              <button type="button" class="btn btn-dark" onClick={() => handleRatingExpert()}>Confirm</button>
            </ModalFooter>


          </Modal>

      }
    </>
  );
};

export default Phone;
