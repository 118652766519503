import React,{useEffect} from 'react'

import {Link} from 'react-router-dom'
import Footer from './Footer'
import NewsletterSubscribed from './NewsletterSubscribed'
const Terms = () => {
   useEffect(()=>{
      window.scrollTo({ top: 0, behavior: "smooth" });
   })
  return (
      <>
      <section className="breadcrumb-section">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">
                  <div className="breadcrumb-content">
                     <ul>
                        <li><Link to="javascript:;">Home</Link> <span><i className="fa fa-angle-right"></i></span></li>
                        <li className="active"><Link to="javascript:;">Terms and Conditions</Link></li>
                     </ul>
                     <h2>Terms & Condition . . . . </h2>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="common-content-page">
         <div className="container">
            <div className="row">
               <div className="col-lg-12">
                  <div>
                     <h3>1. GENERAL:</h3>
                     <p>We start every new user relationship with a contract/agreement. The contract/agreement spells out what you can expect from us, and what we expect from you. We do not know each user personally, so it is important to set out the ground rules clearly in advance. For the purpose of following Terms and Conditions (which may be updated by us from time to time without notice to you), wherever the context so requires as used in this agreement:</p>
                     <br/>
                     <p>The term “We”, “Our”, “Us” refers to Zero7 Connections Pvt. Ltd.</p>
                     <p>The term “Website”, “Vkeel” refers to www.vkeel.com, www.vkeel.in and vkeel Android App.
                     </p>
                     <p>The term “You”, “Your”, “User” shall mean any user of this website or any legal person.
                     </p>
                     <p>The term “Agreement”, “Contract”, “Policy” refers to the Privacy Policy of this website.
                     </p>
                     <p>By accessing and using the Website/App, you accept and agree to be bound by the terms and provision of the User Agreement.If you do not agree with our Terms of Use, do not use this Website/App.
                     </p>
                     <h3>2. DESCRIPTION:</h3>
                     <p>We provide you with a forum to interact with advocates who have registered on this website/app. You understand and agree that information provided on this website/app is for informational purpose only, and does not constitute legal advice. You understand that we may not verify licenses of advocates who have registered on this website/app to provide legal information to users, and that it is your sole responsibility to verify an advocate’s license and credentials before making a decision to hire such an advocate. You understand that we are not an advertising service for advocates, and that the advocate listings on this website/app do not constitute a referral or endorsement by this website.
                     </p>
                     <br/>
                     <p>You understand and agree that we may include certain communications from this website/app, such as service announcements, administrative messages, newsletters, and that these communications are considered part of our membership and you will not be able to opt out of receiving them. You are responsible for obtaining access to this website/app, and that access may involve third-party fees (such as Internet service provider or airtime charges). You are responsible for those fees. In addition, you must provide and are responsible for all equipment necessary to access this website.
                     </p>
                     <h3>3. OUR PRIVACY POLICY:
                     </h3>
                     <p>Registration Data and certain other information about you are subject to our applicable privacy policy. For more information, see the full Privacy Policy. You understand that through your use of this website/app you consent to the collection and use of this information, including the transfer of this information to the United States and/or other countries for storage, processing, and use by website and its affiliates.
                     </p>
                     <h3>4. ACCOUNT SECURITY:
                     </h3>
                     <p>You will receive a password and account designation upon completing registration process. You are responsible for maintaining the confidentiality of the password and account. You are fully responsible for all activities that occur under your password or account. You agree to immediately notify usfor any unauthorized use of your password or account or any other breach of security. You agree to immediately notify us to ensure that you exit from your account at the end of each session. We cannot and will not be liable for any loss or damage arising from your failure.
                     </p>
                     <br/>
                     <p>Transactions on the website/app are secure and protected. Any information entered by the user when transacting on the Website is encrypted to protect the user against unintentional disclosure to third parties. The user’s credit and debit card information is not received, stored by or retained by the website/app in any manner. This information is supplied by the user directly to the relevant payment gateway which is authorized to handle the information provided, and is compliant with the regulations and requirements of various banks and institutions and payment franchisees that it is associated with.
                     </p>
                     <h3>5. COOKIES:
                     </h3>
                     <p>The User is aware that a ‘Cookie’ is a small piece of information stored by a web server on a web browser so it can later be traced back from that particular browser, and that cookies are useful for enabling the browser to remember information specific to a given user, including but not limited to a user’s login identification, password, etc. The user is aware that the website places both permanent and temporary cookies in the user’s computer’s hard drive and web browser, and does hereby expressly consent to the same.
                     </p>
                     <p>The user is further aware that the website uses data collection devices such as cookies on certain pages of the website to help analyze web page flow, measure promotional effectiveness, and promotes trust and safety, and that certain features of the website are only available through the use of such cookies. While the user is free to decline the website’s cookies if the user’s browser permits, the user may consequently be unable to use certain features on the website
                     </p>
                     <p>Additionally, the user is aware that he/she might encounter ‘cookies’ or other similar devices on certain pages of the website that are placed by third parties or affiliates of the website. The user expressly agrees and acknowledges that the website does not control the use of such cookies/other devices by third parties, that the website is in no way responsible for the same, and that the user assumes any and all risks in this regard.
                     </p>
                     <h3>6. MEMBER CONDUCT:</h3>
                     <p>You understand that all information, data, text, software, music, sound, photographs, graphics, video, messages, tags, or other materials (“Content”), whether publicly posted or privately transmitted, are the sole responsibility of the person from whom such Content originated. This means that you are entirely responsible for all Content that you upload, post, email, transmit or otherwise make available via this website/app. We does not control the Content posted via this website/app and, as such, does not guarantee the accuracy, integrity or quality of such Content. You understand that by using this website/app, you may be exposed to Content that is offensive, indecent or objectionable. Under no circumstances will website/app be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted or otherwise made available via this website/app.
                     </p>
                     <br/>
                     <p>You acknowledge that Vkeel may or may not pre-screen Content, but that Vkeel and its designees shall have the right (but not the obligation) in their sole discretion to pre-screen, refuse, or remove any Content that is available via Vkeel. Without limiting the foregoing, Vkeel and its designees shall have the right to remove any Content that violates the User Agreement or is otherwise objectionable. You agree that you must evaluate, and bear all risks associated with, the use of any Content, including any reliance on the accuracy, completeness, or usefulness of such Content. In this regard, you acknowledge that you may not rely on any Content created by Vkeel or submitted to Vkeel, including without limitation information in Vkeel forums and in all other parts of Vkeel.
                     </p>
                     <br/>
                     <p>You acknowledge, consent, and agree that Vkeel may access, preserve, and disclose your account information and Content if required to do so by law or in a good faith belief that such access preservation or disclosure is reasonably necessary to:
                     </p>
                     <br/>
                     <p>comply with legal process;</p>
                     <p>enforce the User Agreement;
                     </p>
                     <p>respond to claims that any Content violates the rights of third parties;
                     </p>
                     <p>respond to your requests for customer service; or
                     </p>
                     <p>protect the rights, property or personal safety of Vkeel, its users, and the public.
                     </p>
                     <p>You understand that Vkeel and software embodied within Vkeel may include security components that permit digital materials to be protected, and that use of these materials is subject to usage rules set by Vkeel and/or content providers who provide content to Vkeel. You may not attempt to override or circumvent any of the usage rules embedded into Vkeel. Any unauthorized reproduction, publication, further distribution, or public exhibition of the materials provided on Vkeel, in whole or in part, is strictly prohibited.
                     </p>
                     <br/>
                     <p>You understand and agree that your Akeel account is non-transferable and any rights to your Vkeel User ID or contents within your account terminate upon your death. Upon receipt of a copy of a death certificate, your account may be terminated and all contents therein permanently deleted. You may terminate your Vkeel account, any associated email address, and access to this website by submitting such termination request to us.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </section>
     <NewsletterSubscribed/>

      <Footer/>
      
      </>
  )
}

export default Terms