import { React, useEffect, useContext, useState } from "react";
import Sidebar from "./Sidebaruser";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import homeAction from "../../actions/home.action";
import moment from "moment";
import ReactPaginate from 'react-paginate';

const Wallet = () => {
  const history = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const [walletData, SetWalletData] = useState([]);
  const [walletBalance, SetWalletBalance] = useState(0);
  const [pages, setPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0)


  useEffect(() => {
    if (isLoggedIn === true) {
      getUserWalletDetails();
    } else {
      history("/");
    }
  }, [currentPage]);
  
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const getUserWalletDetails = async () => {
    homeAction.getUserWalletDetails(currentPage * 10, 10, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        SetWalletData(res.data.transactionData);
        SetWalletBalance(res.data.totalWalletBalance);
        setPages(res.data.pages)
      }
    });
  };
  return (
    <>
      {console.log("walletData", pages)}
      <section className="admin-wrapper">
        <Sidebar />
        <div className="admin-content-wrapper">
          <div className="row">
            <div className="col-lg-12">
              <div className="wallet-recharge-wrp">
                <div className="wallet-recharge-content">
                  <div>
                    <img src="/assets/img/wallet-icon.png" alt="" />
                  </div>
                  <div>
                    <h3>{walletBalance} OMR</h3>
                    <h6>Available Wallet Balance</h6>
                  </div>
                </div>
                <div className="wallet-rec-button">
                  <button
                    className="btn"
                    type="button"
                    onClick={() => {
                      history("/membership");
                    }}
                  >
                    Recharge
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="common-table-wrapper">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <th>
                        Description{" "}
                        <img src="/assets/img/sort-icon.png" alt="" />
                      </th>
                      <th>
                        Amount <img src="/assets/img/sort-icon.png" alt="" />
                      </th>
                      <th>
                        Date <img src="/assets/img/sort-icon.png" alt="" />
                      </th>
                      <th>
                        Status <img src="/assets/img/sort-icon.png" alt="" />
                      </th>
                    </thead>
                    <tbody>
                      {walletData &&
                        walletData.length > 0 &&
                        walletData.map((el, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <h5>{el.description}</h5>
                              </td>
                              <td>
                                {el.type === "credit" ? (
                                  <h6 className="text-success">+ {el.amountPaid} OMR</h6>
                                ) : (
                                  <h6 className="text-danger">- {el.amountPaid} OMR</h6>
                                )}
                              </td>
                              <td>
                                <h6>
                                  {" "}
                                  {moment(el.date).format("DD/MM/YYYY hh:mm a")}
                                </h6>
                              </td>
                              <td>
                                <h6>
                                  {" "}
                                  {el.paymentStatus.charAt(0).toUpperCase() +
                                    el.paymentStatus.slice(1)}
                                </h6>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {pages > 0 ? (<ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={
                      "pagination justify-content-center"
                    }
                    // forcePage={currentPage - 1}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />) : (<div>No Transaction Data</div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wallet;
