import Agent from "./superAgent";
import config from "../config/configg";
import { ServerError } from "../utils/helpers";
const BACKEND_URL = config.BACKEND_URL;

function fetchAllCategories(cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/getCategoriesData`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}

function fetchAllPracticeArea(cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/getPracticeAreaData`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}
function getParticularPracticeAreaCateories(cb) {
  Agent.fire(
    "get",
    `${BACKEND_URL}/admin/getParticularPracticeAreaCateories`
  ).end((err, res) => {
    var error =
      err || res.error
        ? ServerError(res)
        : res.body && res.body.error
        ? ServerError(res)
        : null;
    if (typeof cb === "function") return cb(error, res && res.body);
  });
}

function fetchParticularCategoriesPracticeArea(id, cb) {
  console.log("this is id", id);
  Agent.fire("get", `${BACKEND_URL}/admin/getPracticeAreaCategories/${id}`)
    // .query(selectedCategories)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function fetchAllPracticeAreaInGroups(cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/getPracticeAreaDataInGroups`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}
function getCategories(id, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/getCategoryDetails/${id}`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}
function getPracticeAreaSingle(id, cb) {
  Agent.fire("get", `${BACKEND_URL}/admin/getPracticeAreaDetails/${id}`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}

export default {
  fetchAllCategories,
  fetchAllPracticeArea,
  fetchAllPracticeAreaInGroups,
  fetchParticularCategoriesPracticeArea,
  getCategories,
  getPracticeAreaSingle,
  getParticularPracticeAreaCateories,
};
