import { React, useState, useRef, useEffect, useContext } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import homeAction from "../../actions/home.action";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import StarRatings from "react-star-ratings";
import categoriesAction from "../../actions/categories.action";
import expListingAction from "../../actions/expertlisting.action.js";
import FetchCategoriesList from "./FetchCategoriesList";
import useComponentVisible from "./useComponentVisible";
import { CategoryAndPracticeContext } from "../../context/CategoryAndPracticeContext";
import _ from "lodash";
import NewsletterSubscribed from "./NewsletterSubscribed";
import { TailSpin } from "react-loader-spinner";
import { AuthContext } from "../../context/AuthContext";
import settings from "../../config/configg";
import axios from "axios";
const Home = () => {
  const { isAuthModalOpen, setIsAuthModalOpen, isLoggedIn, setIsLoggedIn } =
    useContext(AuthContext);

  const [mainLoader, setMainLoader] = useState(true);
  // const []
  const [expertLoader, setExpertLoader] = useState(true);
  const [topExpertLoader, setTopExpertLoader] = useState(true);
  const [featureLoader, setFeatureLoader] = useState(true);
  const [legalPracticeArea, setLegalPracticeArea] = useState([]);
  const [dummy, setDummy] = useState(0);
  const [expertsOnline, setExpertsOnline] = useState([]);
  const [getCategories, setGetCategories] = useState([]);
  // const [getPracticeArea, setGetPracticeArea] = useState([]);
  const[plans,setPlans] = useState([])
  const [filteredPracticeArea, setFilteredPracticeArea] = useState([]);
  const [topExpertsList, setTopExpertsList] = useState([]);
  // const [testimonies, setTestimonies] = useState([]);
  const [categorieId, setCategorieId] = useState("");
  const [select, setSelect] = useState(true);
  const [practiceArea, setPracticeArea] = useState([]);
  const { ref, isComponentVisible } = useComponentVisible(false);
  const [practiceAreaId, setPracticeAreaId] = useState("");
  const {
    selectedCategories,
    setSelectedCategories,
    selectedPractice,
    setSelectedPractice,
  } = useContext(CategoryAndPracticeContext);
  // setCategorieId(selectedCategories)
  useEffect(() => {
    // console.log("this is cate" , selectedCategories)
    categoriesAction.fetchParticularCategoriesPracticeArea(
      selectedCategories,
      (err, res) => {
        if (err) {
        } else {
          // console.log(res.data, " use effect data");
          setSelect(false);
          setFilteredPracticeArea(res.data);
        }
      }
    );
  }, [selectedCategories]);
  const getPracticeArea = [
    {original: "/assets/img/criminal-custody-icon.png", name:"Criminal Cases",content:"Expert defense for a range of criminal charges, ensuring fair legal representation"},
    {original: "/assets/img/civil.png", name:"Civil Cases",content:"Resolving disputes between individuals and organizations, with emphasis on negotiation and litigation."},
    {original: "/assets/img/family.png", name:"Family Law Cases",content:"Compassionate legal solutions for family matters, including divorce, custody, and support issues."},
    {original: "/assets/img/property.png", name:"Intellectual Property Cases",content:"Protecting creative works and inventions through robust intellectual property rights enforcement."},
    {original: "/assets/img/money-icon.png", name:"Bankruptcy Cases",content:"Navigating bankruptcy laws to provide financial relief and fresh starts for clients."},
    {original: "/assets/img/gavel-icon.png", name:"Constitutional Cases",content:"Defending constitutional rights and freedoms in complex and significant legal challenges"},
  ]
  // const getPracticeArea = [
  //   {original: "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/man-person-icon.png", name:"Aman"},
  //   {original: "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/man-person-icon.png", name:"Preety"},
  //   {original: "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/man-person-icon.png", name:"Subhash"},
  //   {original: "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/man-person-icon.png", name:"Mahesh"},
  //   {original: "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/man-person-icon.png", name:"Suresh"},
  //   {original: "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/man-person-icon.png", name:"Naman"},
  // ]
  const testimonies = [
    { feedback: `“I can't thank this platform enough! It made client management a breeze, streamlined invoicing, and boosted my efficiency. A must for busy advocates!”`, name: "Sarah M." },
    { feedback: `“Game-changing platform! It's like having a personal assistant for legal tasks. Highly recommended for lawyers looking to simplify their workflow.”`, name: "Olivia Bennett" },
    { feedback: `“A true gem in the legal tech landscape! It's not just user-friendly; it's a game-changer. Revolutionized my practice!”`, name: "Michael C." },
    { feedback: `“This platform is a lifesaver! From client onboarding to payments, it handles it all seamlessly. A crucial tool for modern legal professionals.”`, name: "Alex R." },
    { feedback: `“Invaluable! This platform seamlessly integrates client management, case details, and financial transactions. A must-have for serious advocates!”`, name: "Emily Turner" },
];




  // console.log("this is cate" , selectedCategories)
  // var fetchParticularCategoriesPracticeArea = async () => {
  // if(select==true)
  // categoriesAction.fetchParticularCategoriesPracticeArea(selectedCategories,(err, res) => {
  //   if (err) {

  //   } else {
  //     console.log(res.data, " daata ");
  //     setSelect(false)
  //     practiceArea(res.data);
  //   }
  // });

  // };

  const history = useNavigate();
  const [searchedTermPractice, setSearchedTermPractice] = useState("");

  const handleBookAppointment = (obj) => {
    if (isLoggedIn === true) {
      history(`/expprofile/${obj._id}`);
    } else {
      setIsAuthModalOpen(true);
    }
  };

  const handleEnter = (event) => {
    console.log("event", event.which);
    if (event) {
      event.preventDefault();
    }
    if (
      event.which === 13 &&
      searchedTermPractice.length > 0 &&
      selectedPractice !== ""
    ) {
      history(
        `/expertlisting?selectedPractice=${selectedPractice}&selectedCategories=${selectedCategories}`
      );
    }
  };
  // console.log("this is cccct" , categoriesId)

  const fetchAllPracticeAreaInGroups = async () => {
    categoriesAction.fetchAllPracticeAreaInGroups((err, res) => {
      if (err) {
      } else {
        console.log(res.data, " daata ");
        setLegalPracticeArea(res.data);
      }
    });
  };
  const fetchAllExpertsOnline = async () => {
    setExpertLoader(true);
    homeAction.fetchAllExpertsOnline((err, res) => {
      if (err) {
        console.log(err, " fetchAllExpertsOnline error");
        setExpertLoader(false);
      } else {
        setExpertsOnline(res.data);
        setDummy(0);
        setExpertLoader(false);
      }
      // setMainLoader(false);
    });
  };
  const fetchAllCategories = async () => {
    categoriesAction.fetchAllCategories((err, res) => {
      if (err) {
        console.log(err, "helllooo");
      } else {
        setGetCategories(res.data);
        console.log(res.data, " daata ");
      }
    });
  };
  const fetchSearchedPracticeArea = async () => {
    const dataToSend = {
      searchedTerm: searchedTermPractice,
    };
    homeAction.fetchSearchedPracticeArea(dataToSend, (err, res) => {
      if (err) {
      } else {
        // setFilteredPracticeArea(res.data);
        setDummy(0);
        console.log(res.data, " data ");
      }
    });
  };

  // const fetchAllPracticeArea = async () => {
  //   setMainLoader(true);
  //   categoriesAction.fetchAllPracticeArea((err, res) => {
  //     if (err) {
  //     } else {
  //       setGetPracticeArea(res.data);

  //       setMainLoader(false);
  //     }
  //   });
  // };

  useEffect(() => {
    getPlans()
    fetchAllExpertsOnline();
    setDummy(1);
    fetchAllCategories();
    fetchSearchedPracticeArea();
    fetchTopExperts();
    // fetchTestimonies();
    // fetchAllPracticeArea();
  }, []);
  const handleClick = (e) => {
    if (e.type == "click") {
      e.preventDefault();
    }
    if (searchedTermPractice.length > 0 && selectedPractice !== "") {
      history(
        `/expertlisting?selectedPractice=${selectedPractice}&selectedCategories=${selectedCategories}`
      );
    }
  };

  const redirectSignup = ()=>{
    window.location.href = `${settings.LEGAL_URL}/signup-firm/`;
  }

  const redirectLogin = ()=>{

    window.location.href = `${settings.LEGAL_URL}`;
  }
  const fetchTopExperts = async () => {
    setTopExpertLoader(true);
    expListingAction.fetchTopExperts((err, res) => {
      if (err) {
        console.log(err, " fetchTopExperts error");
        setTopExpertLoader(false);
      } else {
        setTopExpertsList(res.data.list);
        setDummy(0);
        setTopExpertLoader(false);
      }
    });
  };

  // const fetchTestimonies = async () => {
  //   setFeatureLoader(true);
  //   homeAction.fetchTestimonies((err, res) => {
  //     if (err) {
  //       console.log(err, " fetchTestimonies error");
  //       setFeatureLoader(false);
  //     } else {
  //       setTestimonies(res.data);

  //       setDummy(0);
  //       setFeatureLoader(false);
  //       console.log(res.data, " fetchedTestimonies ");
  //     }
  //   });
  // };
  const topLegal = (practiceAreaId) => {
    let id = practiceAreaId;
    history(`/expertlisting?selectedPractice=${id}`);
    // console.log("this is onclick" ,obj)
  };


  const getPlans = async () => {




    const result = await axios({
        method: "GET",
        url: `${settings.BACKEND_URL}/common/getLegalSubscription`,
        headers: {
            "Content-Type": "application/json",
            // "auth-token": localStorage.getItem("token"),
            // 'Accept-Language' : localStorage.getItem("lang"),

        },
    });
    setPlans(result?.data?.data)
}


  return (
    <>
      {/* {featureLoader ? (
        <div className="custm-loader">
          <TailSpin color="#000" height={200} width={200} />
        </div>
      ) : null} */}
{/* ------------------------------hero------------------------ */}
      <section className="main-bn-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="main-bn-content">
                <h1>Simplify Legal Workflow Advanced case Management.</h1>
                <h6>
                Your All-in-One Solution for Effortless Client & Case Management, Seamless Financial Integration, Calendar Coordination, Employee Collaboration and Advanced Analytics.
                </h6>
              </div>
              <div className="mt-5  row">
                <div className="col">

                <button className="btn btn-dark px-5 py-3 mr-4" onClick={redirectSignup}>Register Your Firm</button>
                </div>
                <div className="col">
                <button className="btn btn-dark px-5 py-3 " onClick={redirectLogin}>Login Now</button>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="main-bn-img">
                <img
                  src="/assets/img/man-img.png"
                  className="img img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ---------------------------------legal practice------------------------------- */}
      <section class="pratice-area-wrp" id="legal">
        <div class="container">
          <div class="row mx-0">
            <h3 className="legal-h3">Legal Practice Area</h3>
            <p class="legal-paragraph">
              {/* Get Free Legal Advice Online with one of the best Advocates */}
              {/* Create Firm And Add Clients That Firm
              <br></br> And Track Your Cases */}
              Transform Your Practice: Create a Firm and  
              <br />
              Effortlessly Add Clients
            </p>
            <div class="col-lg-12 mt-3">
              
                <div class="owl-carousel owl-theme pratice-area-owl d-block">
                  <OwlCarousel
                    className="health-owl pratice-area-owl"
                    items={10}
                    loop={true}
                    rewind={true}
                    // nav={true}
                    margin={10}
                    // rewind={true}
                    responsive={{
                      0: {
                        items: 1,
                        nav: false,
                      },
                      600: {
                        items: 2,
                        nav: true,
                      },
                      1000: {
                        items: 4,
                      },
                    }}
                  >
                    {getPracticeArea.length > 0 &&
                      getPracticeArea.map((obj, index) => {
                        return (
                          <div
                            className="item"

                            //  key={index}
                          >
                            <div className="pratice-area-box legal-practice-area">
                              <div className="col">
                                <div className="text-center px-3">
                                  <div className="img-content">
                                    <img
                                      src={`${obj.original}`}
                                      className="img img-fluid"
                                      alt=""
                                      onClick={() => topLegal(obj._id)}
                                    />
                                  </div>

                                  <div>
                                    <h4 onClick={() => topLegal(obj._id)}>
                                      {/* {obj?.name}  */}
                                      {obj?.name}{" "}
                                    </h4>
                                  </div>
                                    <p>{obj.content}</p>
                                    <button onClick={redirectLogin} className="btn rounded-pill px-4 py-3 btn-outline-secondary">Start Now</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </OwlCarousel>
                </div>
            </div>
          </div>
        </div>
      </section>
{/* -------------------------work done-------------------- */}
      <section className="work-gets-done-wrp" id="hwork">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="common-head">
                <h3>See how work gets done</h3>
                <p>
                Optimize Legal Operations with Seamless Client and Case Management{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="hwg-box">
                <div className="">
                  <img
                    src="/assets/img/signup-in-second.png"
                    className="img img-fluid"
                    alt=""
                  />
                </div>
                <h4>Signup Firm in seconds</h4>
                <p>
  Signup with a Borhan firm Account{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="hwg-box">
                <div className="">
                  <img
                    src="/assets/img/select-book.png"
                    className="img img-fluid"
                    alt=""
                  />
                </div>
                <h4>Choose a Plan</h4>
                <p>
                  Choose a plan According to the employees you need{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="hwg-box">
                <div className="">
                  <img
                    src="/assets/img/video-call.png"
                    className="img img-fluid"
                    alt=""
                  />
                </div>
                <h4>Legal Practice Management</h4>
                <p>
                  Get Clients, Case details, Expense Entries, Invoice, Payments {" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* -------------------------------clients ------------------------------------- */}
      {/* ---------------------------membership-------------------------------- */}

      <section className="membership-plan-wrp">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="common-head">
                <h3>Choose Membership Plans</h3>
                <p>Get Discounts on Bookings </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">

            {/* <div className="col-lg-4">
              <div className="membership-plan-box">
                <div className="side-indicator"></div>
                <h3>Basic</h3>
                <h5>
                  <img
                    src="/assets/img/clock-green.png"
                    className="img img-fluid"
                    alt=""
                  />{" "}
                  10h / month
                </h5>
                <h4>
                  {" "}
                  <span></span> OMR 8
                </h4>
                <h4>
                  <b>5.5 OMR</b>
                </h4>
                <h6>Billed Monthly</h6>
                <p>As one payment of 5.5 $</p>
                <button className="btn" type="button">
                  Buy Basic
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="membership-plan-box active">
                <div className="bv-tag">Best Value</div>
                <div className="side-indicator"></div>
                <h3>Premium</h3>
                <h5>
                  <img
                    src="/assets/img/clock-yellow.png"
                    className="img img-fluid"
                    alt=""
                  />{" "}
                  22h / month
                </h5>
                <h4>
                  {" "}
                  <span></span> OMR 100
                </h4>
                <h4>
                  <b>60 OMR</b>
                </h4>
                <h6>Billed Annual</h6>
                <p>As one payment of 60 $</p>
                <button className="btn" type="button">
                  Buy Premium
                </button>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="membership-plan-box">
                <div className="side-indicator"></div>
                <h3>Advance</h3>
                <h5>
                  <img
                    src="/assets/img/clock-green.png"
                    className="img img-fluid"
                    alt=""
                  />{" "}
                  22h / month
                </h5>
                <h4>
                  {" "}
                  <span></span> OMR 8
                </h4>
                <h4>
                  <b>5.5 OMR</b>
                </h4>
                <h6>Billed Monthly</h6>
                <p>As one payment of 5.5 $</p>
                <button className="btn" type="button">
                  Buy Advance
                </button>
              </div>
            </div> */}
             {
                                        plans.length>0?    plans.map((plan, index) => {
                                                return (
                                                    <>
                                                            <div className="col-lg-4" key={plan._id}>
                                                                <div className="membership-plan-box">
                                                                    <div className="side-indicator"></div>
                                                                    <h3>{plan.planName}</h3>
                                                                    <h5 className="my-4"><img src="/assets/img/clock-green.png" className="img img-fluid" alt="" /> {`${plan.employeesNum} employees`}</h5>
                                                                    <h4 className="my-4"> <span></span> {+plan.planAmount} OMR</h4>
                                                                    <h4 className="my-4"><b>{plan.planAmount -plan.discountValue} OMR</b></h4>
                                                                    <h6 className="my-4">{plan.planDuration} Months</h6>
                                                                    <button className="btn" type="button" onClick={redirectSignup}>Buy Plan</button>
                                                                </div>
                                                            </div>
                                                    </>
                                                )

                                            })
                                            :"No Plans Available"
                                        }
          </div>
        </div>
        <div className="mem-plan-layer">
          <img
            src="/assets/img/membership-plan-bg.png"
            className="img img-fluid"
            alt=""
          />
        </div>
      </section>
      
{/* ------------------------- grow faster-------------------------- */}
      <section className="premium-expert-wrp">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="premium-expert-img">
                <img
                  src="/assets/img/premium-expert.png"
                  className="img img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="premium-expert-content">
                <div>
                  <h1>Grow Faster With Case Management</h1>
                  <p>
                  Get a 360-degree view of your law firm's prospective client pipeline. Attorneys and staff can track cases from first contact through to signing, and see exactly where there's room to grow
                  </p>
                  <ul>
                    <li>Customize web-based client intake forms for any area of your practice</li>
                    <li>Handle the client intake process from start to finish</li>
                    <li>Effortlessly manage and organize client information securely</li>
                  </ul>
                  {/* <button className="btn text-dark" type="button">
                    <Link to="/premiumexpert" class="text-dark">
                      View all Premium Expert
                    </Link>
                  </button> */}
                  <button className="btn" type="button" onClick={redirectSignup}>Try Legal Practice Now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

{/* Testimonials */}
      <section className="client-feature-wrp" id="testi">
        <div className="container px-3">
          <div className="row mx-0 ">
            <div className="col-lg-12 ">
              <div className="common-head text-center">
                <h3>What our clients have to say Features</h3>
              </div>
            </div>
          </div>
          <div className="row mx-0 mt-4 position-relative">
              <div className="col-lg-12">
                <OwlCarousel
                  className="health-owl client-feature-owl"
                  items={10}
                  loop={true}
                  rewind={true}
                  margin={10}
                  responsive={{
                    0: {
                      items: 1,
                      nav: true,
                    },
                    600: {
                      items: 1,
                      nav: true,
                    },
                    1000: {
                      items: 2,
                      nav: true,
                    },
                  }}
                >
                  {testimonies &&
                    testimonies.map((obj, index) => {
                      return (
                        <div className="item">
                          <div className="client-feature-box">
                            <div className="client-feature-img">
                              <img
                                src= "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/man-person-icon.png"
                                className="img img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="client-feature-content">
                              <p>{obj?.feedback}</p>
                              <div className="d-flex align-items-center gap-2">
                                <img src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" style={{width:"20px"}} alt="star"/>
                                <img src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" style={{width:"20px"}} alt="star"/>
                                <img src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" style={{width:"20px"}} alt="star"/>
                                <img src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" style={{width:"20px"}} alt="star"/>
                                <img src="https://cdn-icons-png.flaticon.com/512/6201/6201789.png" style={{width:"20px"}} alt="star"/>
                                </div>
                              <h5>{obj?.name}</h5>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </OwlCarousel>
              </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
