import { React, useState, useEffect, useContext } from 'react'
import { Modal, ModalBody, ModalFooter, } from "reactstrap";
import { Link, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebaruser'
import { AuthContext } from '../../context/AuthContext';
import appointmentAction from '../../actions/appointment.action'
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import { TailSpin } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import expertAction from "../../actions/expertlisting.action";
import Countdown from 'react-countdown';

import Calendar from 'react-calendar'

const AppointmentDetails = () => {
   const [mainLoader, setMainLoader] = useState(true)
   const [sizePerPage, setSizePerPage] = useState(4);
   const [pages, setPages] = useState(5);
   const [currentPage, setCurrentPage] = useState(1);
   const [reschedulemodal, setreschedulemodal] = useState(false);
   const { isLoggedIn, setIsLoggedIn, loggedInBorhanUserInfo, setLoggedInBorhanUserInfo } = useContext(AuthContext);
   const [filterType, setFilterType] = useState("Upcoming");
   const [dummy, setDummy] = useState(0);
   const [appointmentList, setAppointmentList] = useState([]);
   const [rescheduleModalDetails, setRescheduleModalDetails] = useState();
   const [timeSlotId, setTimeSlotId] = useState("");
   const [timeList, setTimeList] = useState([]);
   const [dateSlot, SetDateSlot] = useState(null)
   const [startAppointmentTime, SetStartAppointmentTime] = useState(null)
   const [endAppointmentTime, SetEndAppointmentTime] = useState(null)
   const [Duration, setDuration] = useState(null);
   const [appointmentToBeRescheduled, setAppointmentToBeRescheduled] = useState();
   const [isTimeSelected, setIsTimeSelected] = useState(false);
   const history = useNavigate();
   useEffect(() => {
      fetchTime();
   }, [dateSlot, Duration])
   //moment().format('MMMM Do YYYY, h:mm:ss a');

   const fetchTime = () => {
      console.log(Duration, dateSlot, "sdclkndcs")
      let id = appointmentToBeRescheduled?.expertId?._id;
      //  console.log(appointmentToBeRescheduled,'asssssfddasf')
      if (!Duration && !dateSlot) {
         return;
      }
      let payload = {
         expertId: id,
         appointmentDate: dateSlot,
         duration: Duration
      }
      expertAction.getAvailableTimeForUser(payload, (err, res) => {
         if (err) {

         } else {
            console.log(res);
            setTimeList(res.data);

         }
      })

   }

   const rescheduleAppointment = () => {
      console.log("this is reschedule", appointmentToBeRescheduled)
      let id = appointmentToBeRescheduled.expertId._id;
      let previousTimeSlot = appointmentToBeRescheduled.timeSlotId;

      setMainLoader(true);
      let calltype = "";
      if (appointmentToBeRescheduled.appointmentType == 'video') {
         calltype = "video";
      } else if (appointmentToBeRescheduled.appointmentType == 'audio') {
         calltype = "audio";
      } else {
         toast('something went wrong')
         return
      }
      let dataToSend = {
         duration: Duration,
         timeSlotId: timeSlotId,
         appointmentTime: startAppointmentTime,
         appointmentDate: dateSlot,
         practiceArea: "625fef06f962a2761cd19e74",
         expertId: id,
         appointmentType: calltype,
         appointDateandTime: startAppointmentTime,
         status: "pending",
         startAppointmentTime: startAppointmentTime,
         endAppointmentTime: endAppointmentTime,
         appointmentId: appointmentToBeRescheduled._id,
         previousTimeSlot: previousTimeSlot,
         isRescheduled: true,
      }
      console.log(dataToSend, "data playload")
      expertAction.rescheduleAnAppoitment(dataToSend, (err, res) => {
         if (err) {
            toast('something went wrong please try again');
         } else {
            toast(" Successfully sent Reschedule appointment request ");
            setTimeout(() => {
               window.location.reload();
            }, 100)
            // console.log(res,"here is resposnse in exprofile.js")
         }
         setreschedulemodal(false);
         GetAppointments();
         setMainLoader(false);

      })


   }
   const Datehandel = (e) => {
      // api hit 

      e = moment(e).format('YYYY-MM-DD');
      console.log(e, "date to selected")
      SetDateSlot(e);
      // fetchTime();

   }
   const handlemodal1 = () => {

      if (startAppointmentTime == null || dateSlot == null) {
         toast("Please selec Appointment Date And Time Slot ")
         return
      }
      console.log("hello")
      rescheduleAppointment();
      setreschedulemodal(false);
      // setscheduleappointmentmodal(false);
      // setbookappointmentmodal(false);
   }
   const setinterval1 = (e) => {

      let start = moment.utc(e.startAppointmentTime).local().format("HH:mm ");
      let end = moment.utc(e.endAppointmentTime).local().format(" HH:mm ")
      //  let start=moment(start1).local().format("HH:mm");
      //  let end=  moment(end1).local().format(" HH:mm")

      console.log(start + "-" + end);
      return start + "-" + end
   }

   const onSelectTime = (start, end) => {

      // e=moment(e).format('YYYY-MM-DD');
      // console.log(e,"date to selected")
      // console.log(e)
      if (dateSlot) {
         toast("Time slot selected")
         if (1) {
            let localdate = dateSlot + ' ' + start;
            console.log(localdate, "here is ");
            console.log(moment(localdate), 'locaDate')
            console.log(moment.utc(moment(localdate)).format(), 'start time')
            SetStartAppointmentTime(start);
            let afterSomeMinutes = dateSlot + ' ' + end;
            console.log(afterSomeMinutes, end, "here is ");
            console.log(moment(afterSomeMinutes), 'locaDate')
            console.log(moment.utc(moment(afterSomeMinutes)).format(), 'end time')
            SetEndAppointmentTime(end);

         }
      }
   }
   useEffect(() => {
      if (isLoggedIn === true) {
         GetAppointments();
      }
      else {
         history('/')
      }
   }, [filterType, currentPage, dummy])
   const CancelAppointment = async (obj) => {
      let dataToSend = {
         id: obj._id
      }
      appointmentAction.cancelAppointment(dataToSend, (err, res) => {
         if (err) {
            console.log(err, " appointments error")
         } else {
            console.log("successfully cancelled", res.data)
            if (dummy == 1) {
               setDummy(0);
            }
            else
               setDummy(1);
         }
      });
   }

   const handlePageClick = (data) => {
      let current = data.selected + 1;
      console.log(current, "currentpage");
      setCurrentPage(current);
   };
   const GetAppointments = async () => {
      setMainLoader(true);
      let dataToSend = {
         filterType: filterType,
         limit: sizePerPage,
         page: currentPage,
      }
      appointmentAction.getAppointments(dataToSend, (err, res) => {
         if (err) {
            console.log(err, " appointments error")
         } else {
            setAppointmentList(res.data.list);

            setPages(
               parseInt(res.data.count % sizePerPage) == 0
                  ? parseInt(res.data.count / sizePerPage)
                  : parseInt(res.data.count / sizePerPage + 1)
            );
            setDummy(0);
            console.log(res.data, " Apoointments are");
         }
         setMainLoader(false);
      });

   };

   const twilioVideoChat = (obj) => {
      history('/videochat',
         // search: 'abcd',
         { state: { appointmentId: obj._id } }
      )
   }
   const audioCall = (obj) => {
      // console.log("obj", obj)
      history('/audiocall',
         { state: { appointmentId: obj._id } })
   }

   const handleMeeting = (obj) => {
      if (obj.appointmentType == 'video') {
         twilioVideoChat(obj);
      }
      else if (obj.appointmentType == 'audio') {
         audioCall(obj);
      }
   }
   const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
         window.location.reload();
         return (
            <a >join</a>);
      }
      else {
         return <span>{days}:{hours}:{minutes}:{seconds}</span>;
      }
   }

   return (

      <>
         <ToastContainer />
         {mainLoader ? (
            <div className="custm-loader">
               <TailSpin color="#000" height={200} width={200} />
            </div>
         ) : null}

         <section className="admin-wrapper">
            <Sidebar />
            <div className="admin-content-wrapper">
               <div className="row">
                  <div className="col-lg-12">
                     <div className="user-appoinment-details">
                        <div className="appointment-tabs">
                           <ul className="nav nav-pills" id="pills-tab" role="tablist">
                              <li className={`nav-item ${filterType == 'All' ? 'active' : ''}`} role="presentation">
                                 <button className={`nav-link ${filterType == 'All' ? 'active' : ''}`} id="pills-all-tab"
                                    type="button" role="tab" aria-controls="pills-all   "
                                    aria-selected="true" onClick={() => { setFilterType("All") }}>All</button>
                              </li>
                              <li className={`nav-item ${filterType == 'Upcoming' ? 'active' : ''}`} role="presentation">
                                 <button className={`nav-link ${filterType == 'Upcoming' ? 'active' : ''}`} id="pills-upcoming-tab"
                                    type="button" role="tab" aria-controls="pills-upcoming"
                                    aria-selected="false" onClick={() => { setFilterType("Upcoming") }}>Upcoming</button>
                              </li>
                              <li className={`nav-item ${filterType == 'Reschedule' ? 'active' : ''}`} role="presentation">
                                 <button className={`nav-link ${filterType == 'Reschedule' ? 'active' : ''}`} id="pills-reschedule-tab" type="button" role="tab"
                                    aria-controls="pills-reschedule" aria-selected="false" onClick={() => { setFilterType("Reschedule") }}>Reschedule</button>
                              </li>
                              <li className={`nav-item ${filterType == 'Completed' ? 'active' : ''}`} role="presentation">
                                 <button className={`nav-link ${filterType == 'Completed' ? 'active' : ''}`} id="pills-completed-tab" type="button" role="tab" aria-controls="pills-completed"
                                    aria-selected="false" onClick={() => { setFilterType("Completed") }}>Completed</button>
                              </li>
                              <li className={`nav-item ${filterType == 'Cancelled' ? 'active' : ''}`} role="presentation">
                                 <button className={`nav-link ${filterType == 'Cancelled' ? 'active' : ''}`} id="pills-cancelled-tab" type="button" role="tab" aria-controls="pills-cancelled"
                                    aria-selected="false" onClick={() => { setFilterType("Cancelled") }}>Cancelled</button>
                              </li>
                              <li className={`nav-item ${filterType == 'rejected' ? 'active' : ''}`} role="presentation">
                                 <button className={`nav-link ${filterType == 'rejected' ? 'active' : ''}`} id="pills-cancelled-tab" type="button" role="tab" aria-controls="pills-rejected"
                                    aria-selected="false" onClick={() => { setFilterType("rejected") }}>Rejected</button>
                              </li>
                           </ul>
                           <div className="tab-content" id="pills-tabContent">
                              <div className="tab-pane fade show active " id="pills-all" role="tabpanel"
                                 aria-labelledby="pills-all-tab">
                                 <div className="common-table-wrapper ">
                                    <div className="table-responsive table-content-align">
                                       <table className="table">
                                          <tbody>
                                             {/* {console.log("appoii", appointmentList)} */}
                                             {
                                                appointmentList.length > 0 && appointmentList.map((obj, index) => {
                                                   var today = new Date();
                                                   var startTime = new Date(obj.startAppointmentTime);
                                                   let endTime = new Date(obj.endAppointmentTime);
                                                   var dif = (startTime - today);
                                                   var dif = Math.round((dif / 1000) / 60);
                                                   var diffend = (endTime - today);
                                                   var diffend = Math.round((diffend / 1000) / 60);
                                                   let canBeRescheduled = false;
                                                   if (dif >= 60 && obj.status == "confirmed") {
                                                      canBeRescheduled = true;
                                                   }
                                                   else {
                                                      canBeRescheduled = false;
                                                   }
                                                   let flag = false;
                                                   // if(dif<=5&&dif>=0&&obj.status=="confirmed"){
                                                   //    flag=true;
                                                   // } 
                                                   if (diffend <= obj.duration && diffend >= 0 && obj.status == "confirmed") {
                                                      flag = true;
                                                   }
                                                   console.log(diffend, obj.duration, endTime, "sdfafd", dif, flag, "kjjkkjkjk")
                                                   return (
                                                      <tr>
                                                         <td>
                                                            <div className="table-user">
                                                               <div>
                                                                  {/* {console.log("this is userData" , obj)} */}
                                                                  <img src={`${obj?.expertId?.document[0]?.link === '' ? "/assets/img/expert-thumb.png" : obj?.expertId?.document[0]?.link}`} className="img img-fluid" alt="" />
                                                               </div>
                                                               <div>
                                                                  <h5>{obj?.expertId?.userId?.firstName}</h5>
                                                                  <p>{obj?.expertId?.practiceArea[0]?.name}</p>
                                                               </div>
                                                            </div>
                                                         </td>
                                                         <td>
                                                            <h6>{moment(obj?.appointmentDate).format("MMM Do YY")}</h6>
                                                            <h6>{moment(obj?.appointmentTime).format(" h:mm a")}</h6>
                                                         </td>
                                                         <td>
                                                            <h6>{obj?.appointmentType}</h6>
                                                            <h6>{moment(obj?.startAppointmentTime).format("MMM Do YY h:mm:ss a")}</h6>
                                                         </td>
                                                         {canBeRescheduled ?
                                                            <td>

                                                               <div className="table-btn-group">
                                                                  <button className="btn" type="button" onClick={() => { CancelAppointment(obj) }} >Cancel</button>

                                                                  <button
                                                                     // data-bs-target="#appointmentDetailsModal"  data-bs-dismiss="modal" data-bs-toggle="modal" 
                                                                     className="btn" type="button"
                                                                     onClick={() => { setreschedulemodal(true); setAppointmentToBeRescheduled(obj) }}
                                                                  >Reschedule</button>


                                                               </div>
                                                            </td>
                                                            :
                                                            obj.status == 'cancelled' ?
                                                               <td>
                                                                  <div className="table-btn-group ">
                                                                     <button className="btn w-auto" type="button" disabled> {obj.isRejectedByUser ? "Cancelled By You " : "Cancelled By Expert"}</button>

                                                                  </div>
                                                               </td> :
                                                               obj.status == 'completed' ? <td>
                                                                  <div className="table-btn-group">
                                                                     <button
                                                                        // data-bs-target="#appointmentDetailsModal"  data-bs-dismiss="modal" data-bs-toggle="modal" 
                                                                        className="btn" type="button"
                                                                        // onClick={()=>{setreschedulemodal(true);setRescheduleModalDetails(obj)}}
                                                                        disabled>Completed</button>
                                                                  </div>
                                                               </td> :
                                                                  obj.status == 'pending' ? <td>
                                                                     <div className="table-btn-group">
                                                                        <button
                                                                           // data-bs-target="#appointmentDetailsModal"  data-bs-dismiss="modal" data-bs-toggle="modal" 
                                                                           className="btn" type="button"
                                                                           //   onClick={()=>{setreschedulemodal(true);setRescheduleModalDetails(obj)}}
                                                                           disabled>Pending</button>
                                                                     </div>
                                                                  </td> :
                                                                     obj.status == 'rejected' ? <td>
                                                                        <div className="table-btn-group">
                                                                           <button
                                                                              // data-bs-target="#appointmentDetailsModal"  data-bs-dismiss="modal" data-bs-toggle="modal" 
                                                                              className="btn" type="button"
                                                                              //   onClick={()=>{setreschedulemodal(true);setRescheduleModalDetails(obj)}}
                                                                              disabled>rejected</button>
                                                                        </div>
                                                                     </td> :
                                                                        flag ?
                                                                           <td>
                                                                              <div className="table-btn-group">
                                                                                 {/* <button className="btn" type="button" onClick={()=>{CancelAppointment(obj)}} >Cancel</button> */}

                                                                                 <button style={{ backgroundColor: "green" }} className="btn" type="button" onClick={() => { handleMeeting(obj) }} >Join</button>

                                                                              </div>
                                                                           </td> :
                                                                           <td>
                                                                              <div className="table-btn-group">

                                                                                 <p style={{ paddingRight: '10px' }} >Time left to join</p>
                                                                                 <button className="btn"><Countdown date={startTime}
                                                                                    renderer={renderer}>
                                                                                    {/* <Completionist /> */}
                                                                                 </Countdown></button>

                                                                              </div>
                                                                           </td>}
                                                      </tr>)
                                                })
                                             }

                                          </tbody>
                                       </table>
                                       {appointmentList.length > 0 ? <>

                                          <ReactPaginate
                                             previousLabel={"Previous"}
                                             nextLabel={"Next"}
                                             breakLabel={"..."}
                                             pageCount={pages}
                                             marginPagesDisplayed={1}
                                             pageRangeDisplayed={3}
                                             onPageChange={handlePageClick}
                                             containerClassName={
                                                "pagination justify-content-center"
                                             }
                                             forcePage={currentPage - 1}
                                             pageClassName={"page-item"}
                                             pageLinkClassName={"page-link"}
                                             previousClassName={"page-item"}
                                             previousLinkClassName={"page-link"}
                                             nextClassName={"page-item"}
                                             nextLinkClassName={"page-link"}
                                             breakClassName={"page-item"}
                                             breakLinkClassName={"page-link"}
                                             activeClassName={"active"}
                                          /></>

                                          : filterType == "All" ? "No Any Appointment" : filterType == "Upcoming" ? "No Upcoming Appointment" : filterType == "Reschedule" ? " No Reschedule Appointment" : filterType == "Completed" ? "No Any Completed Appointment" : filterType == 'rejected' ? "No Rejected Appointment" : "No Cancelled Appointment"
                                       }
                                    </div>
                                 </div>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>



         {/* <!-- appointment details Modal --> */}
         <div className="modal fade authentication-modal" id="appointmentDetailsModal" tabindex="-1" aria-labelledby="appointmentDetailsModal" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-xl">
               <div className="modal-content">
                  <div className="modal-body">
                     <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                     <div className="auth-modal-wrp">
                        <div className="row">
                           <div className="col-lg-12">
                              <div className="auth-modal-content">
                                 <div className="w-100">
                                    <div className="appointment-deatils-box">
                                       <h1>Appointment details</h1>
                                       <div className="row">
                                          <div className="col-lg-12">
                                             <div className="appointment-details-modal-wrp">
                                                <div className="app-user-wrp">
                                                   <div>
                                                      <img src="/assets/img/megan-smith.png" alt="" />
                                                   </div>
                                                   <div>
                                                      <h4>Meghan Smith</h4>
                                                      <h6>Legal Expert</h6>
                                                   </div>
                                                </div>
                                                <div className="">
                                                   <p>Date</p>
                                                   <h3>September 10th</h3>
                                                </div>
                                                <div className="">
                                                   <p>Time</p>
                                                   <h3>03:30 PM</h3>
                                                </div>
                                                <div className="">
                                                   <p>Mode</p>
                                                   <h3>Video call</h3>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row mb-4">
                                          <div className="col-lg-7">
                                             <div className="app-agenda-box">
                                                <Link to="javascript:;">Edit</Link>
                                                <h3>Appointment Agenda</h3>
                                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum pas</p>
                                             </div>
                                          </div>
                                          <div className="col-lg-5">
                                             <div className="app-agenda-box">
                                                <h3>Available Balance</h3>
                                                <h2>04 h 30 min</h2>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="modal-footer-btn-group">
                                          <button className="btn" data-bs-dismiss="modal" type="button">Cancel</button>
                                          <button className="btn" type="button">Reschedule</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <Modal className="authentication-modal modal-dialog modal-dialog-centered modal-xl"
            isOpen={
               reschedulemodal
            }
            toggle={() => {
               setreschedulemodal(false);
            }
            } >
            <div className="modal-content">
               <div className="modal-body">
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setreschedulemodal(false) }}></button>
                  <div className="auth-modal-wrp">
                     <div className="row">
                        <div className="col-lg-12">
                           <div className="auth-modal-content">
                              <div className="w-100">
                                 <div className="book-appointment-box">
                                    <h1>Schedule an Appointment</h1>
                                    <div className="row">
                                       <div className="col-lg-6">
                                          <div className="book-app-box">
                                             <div className="schedule-calendar">
                                                <Calendar
                                                   minDate={new Date()}
                                                   onClick={e => {
                                                      // e.preventDefault();

                                                      console.log(e);
                                                   }}
                                                   onChange={e => {
                                                      // e.preventDefault()
                                                      Datehandel(e);
                                                   }} />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-lg-6">

                                          <div className="book-app-box">

                                             <h1> Appointment Time</h1>
                                             <div className="col-lg-6">
                                                <div className="form-group">
                                                   {/* <label for="">Select call Duration time</label> */}
                                                   <select className="form-select " aria-label="example" onChange={e => {
                                                      console.log(e.target.value);
                                                      setDuration(e.target.value);
                                                   }}>
                                                      <option selected value={0}>Select Duration</option>
                                                      <option value={15}>15 Minutes</option>
                                                      <option value={30}>30 Minutes</option>
                                                      <option value={45}>45 Minutes</option>
                                                      <option value={60}>60 Minutes</option>
                                                   </select>
                                                </div>
                                             </div>
                                             <div className="select-time-slot">

                                                {/* <img src="/assets/img/time-slot.png" className="img img-fluid" alt=""/> */}
                                                <div className='row '>
                                                   {
                                                      timeList.length > 0 ? timeList.map((e, index) => {
                                                         return (
                                                            <div className='col-lg-6 btn-appointment1 '>
                                                               {console.log("this is appointment", e.isAvailable)}
                                                               {e.isAvailable ? <button className={`btn ${isTimeSelected ? timeSlotId == e._id ? 'btn-dark' : 'btn-white bg-white' : 'btn-white bg-white'}    btn-appointment`} onClick={(val) => {
                                                                  val.preventDefault();
                                                                  onSelectTime(e.startAppointmentTime, e.endAppointmentTime)
                                                                  setTimeSlotId(e._id);
                                                                  setIsTimeSelected(true);
                                                                  // toast(e.+"selected time id")
                                                               }}>
                                                                  {setinterval1(e)}
                                                               </button> :
                                                                  <button className="btn btn-white bg-white btn-appointment" disabled>
                                                                     {setinterval1(e)}
                                                                  </button>
                                                               }
                                                            </div>)
                                                      }) : <div text-algin="center">No Slot Available</div>
                                                   }

                                                </div>

                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="continue-booking-btn p-0 mb-4">
                                    <button className="btn" type="button" onClick={(e) => {
                                       e.preventDefault();

                                       handlemodal1()
                                    }}> Proceed and Pay </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>

      </>
   )
}

export default AppointmentDetails