import { React, useEffect, useContext, useState } from "react";
import Sidebar from "./Sidebaruser";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import homeAction from "../../actions/home.action";
import { TailSpin } from "react-loader-spinner";

const SavedCards = () => {
  const history = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const [cardData, SetCardData] = useState([]);
  const [mainLoader, setMainLoader] = useState(true);

  useEffect(() => {
    if (isLoggedIn === true) {
      getUserPaymentMethodDetails();
    } else {
      history("/");
    }
  }, []);

  const getUserPaymentMethodDetails = async () => {
    homeAction.getUserPaymentMethodDetails((err, res) => {
      if (err) {
        console.log(err);
      } else {
        SetCardData(res.data);
        setMainLoader(false)
      }
    });
  };
  return (
    <>
      <section className="admin-wrapper">

        <Sidebar />
        {mainLoader ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200} />
          </div>
        ) : (
          <div className="admin-content-wrapper">

            <div className="row">
              {cardData &&
                cardData.length > 0 &&
                cardData.map((el, i) => {
                  return (
                    <div className="col-lg-4" key={i}>
                      <div className="cardsbar">
                        <div className="head">
                          <h4>{el.card_type}</h4>
                        </div>
                        <div className="chipbar">
                          <img src="/assets/img/chipcard.png" alt="img" />
                        </div>
                        <h4 className="card-no">{el.masked_card}</h4>
                        <div className="cardname">
                          <h6>Valid<br />thru <span>{el.expiry_month}/{el.expiry_year}</span></h6>
                          <h3>{el.nickname}</h3>
                        </div>
                        <div className="brand">
                          <h5>{el.brand}</h5>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}

      </section>
    </>
  );
};

export default SavedCards;
