import { React, useEffect, useContext, useState } from "react";
import Sidebar from "./Sidebaruser";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import homeAction from "../../actions/home.action";
import moment from "moment";

const ManageMembership = () => {
  const history = useNavigate();
  const { isLoggedIn } = useContext(AuthContext);
  const [planData, SetPlanData] = useState([]);

  useEffect(() => {
    if (isLoggedIn === true) {
      getUserAllActivePlans();
    } else {
      history("/");
    }
  }, []);

  const getUserAllActivePlans = async () => {
    homeAction.getUserAllActivePlans((err, res) => {
      if (err) {
        console.log(err);
      } else {
        console.log("res", res);
        SetPlanData(res.data);
      }
    });
  };
  return (
    <>
      <section className="admin-wrapper">
        <Sidebar />
        <div class="admin-content-wrapper">
          <div class="row">
            <div class="col-lg-12">
              <div class="common-table-wrapper ">
                <div class="table-responsive membership-table-wrapper">
                  {planData && planData.length > 0 ? (
                    planData.map((el, i) => {
                      return (
                        <table class="table manage-membership-table" key={i}>
                          <thead>
                            <th>
                              <h5>
                                {" "}
                                {el.planName.charAt(0).toUpperCase() +
                                  el.planName.slice(1)}
                              </h5>
                            </th>
                            <th></th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <h5>Wallet Amount Received</h5>
                              </td>
                              <td>
                                <h6>{el.walletAmountReceived} OMR</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5>Wallet Amount Remaining</h5>
                              </td>
                              <td>
                                <h6>{el.walletBalance} OMR</h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5>Date of purchase</h5>
                              </td>
                              <td>
                                <h6>
                                  {moment(el.planBoughtAt).format("DD/MM/YYYY")}
                                </h6>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h5>Expiry Date</h5>
                              </td>
                              <td>
                                <h6>
                                  {moment(el.expiryDate).format("DD/MM/YYYY")}
                                </h6>
                              </td>
                            </tr>
                            {/* <tfoot>
                              <th>
                                <h5> Plan For Upgrades</h5>
                              </th>
                              <th></th>
                            </tfoot> */}
                          </tbody>
                        </table>
                      );
                    })
                  ) : (
                    <div>No Subscription Bought</div>
                  )}
                </div>
              </div>
              <div>
                <button className="btn btn-success" onClick={()=>{history("/membership")}}>Buy Membership</button>
              </div>
              {/* <div class="container-fluid">
                <div class="row d-flex justify-content-end">
                  <div class="col-xl-3 col-lg-5 ">
                    <div class="membership-plan-box">
                      <div class="side-indicator"></div>
                      <h3>Advance</h3>
                      <h5>
                        <img
                          src="/assets/img/clock-green.png"
                          class="img img-fluid"
                          alt=""
                        />{" "}
                        22h / month
                      </h5>
                      <h4>
                        {" "}
                        <span></span> OMR 8
                      </h4>
                      <h4>
                        <b>5.5 OMR</b>
                      </h4>
                      <h6>Billed Monthly</h6>
                      <p>As one payment of 5.5 $</p>
                      <button class="btn" type="button">
                        Buy Advance
                      </button>
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-5 ">
                    <div class="membership-plan-box active">
                      <div class="bv-tag">Best Value</div>
                      <div class="side-indicator"></div>
                      <h3>Premium</h3>
                      <h5>
                        <img
                          src="/assets/img/clock-yellow.png"
                          class="img img-fluid"
                          alt=""
                        />{" "}
                        22h / month
                      </h5>
                      <h4>
                        {" "}
                        <span></span> OMR 100
                      </h4>
                      <h4>
                        <b>60 OMR</b>
                      </h4>
                      <h6>Billed Annual</h6>
                      <p>As one payment of 60 $</p>
                      <button class="btn" type="button">
                        Buy Premium
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ManageMembership;
