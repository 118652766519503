import React from "react";
const PageNotFound = () => {
  return (
    <>
      <section class="page-fourzero-banner">
        <div class="container"></div>
      </section>
      <section class="fourzero-content">
        <div class="container">
          <h4>Page not Found</h4>
          <p>
            You didn't break the internet but we can't find what are you looking
            for
          </p>
          <div class="gohome">
            <a href="/" class="btn btn-outline" type="button">
              Return Home
            </a>
          </div>
        </div>
      </section>
    </>
  );
};
export default PageNotFound;
