import React, { useContext, useRef } from "react";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import loginAction from "../../actions/login.action";
import categoriesAction from "../../actions/categories.action";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config/configg";
import axios from "axios";
import Cookies from "universal-cookie";
import Agent from "../../actions/superAgent";
import { GoogleLogin, GoogleLogout } from "react-google-login";
// import FacebookLogin from 'react-facebook-login';
import { AuthContext } from "../../context/AuthContext";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  FacebookAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "../../firebase";
import { TailSpin } from "react-loader-spinner";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import socialMediaLink from "../../utils/appConstant";
import app from "../../firebase";
import "firebase/messaging";
import {
  getMessaging,
  onMessage,
  getToken,
  isSupported,
} from "firebase/messaging";
// const auth = getAuth();
// config.app.initializeApp({
//   serviceAccountId: 'my-client-id@my-project-id.iam.gserviceaccount.com',
// });

const Header = () => {
  let ref = useRef(null);
  let location = useLocation();

  const [toggleButton, setToggleButton] = useState(false);
  const { isAuthModalOpen, setIsAuthModalOpen, isLoggedIn, setIsLoggedIn } =
    useContext(AuthContext);
  // let CLIENT_ID=""
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const CLIENT_ID =
    "192073990165-k8uk1edbbhb0lm03lqb7ikvf3ibqotr5.apps.googleusercontent.com";
  const GOOGLE_CLIENT_SECRET = "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";
  const [firebaseMobileUid, setFirebaseMobileUid] = useState("");
  const history = useNavigate();
  const [videoName, setVideoName] = useState("");
  const [audioName, setAudioName] = useState("");
  const [accountType, setaccountType] = useState("expert");
  const [files, setFile] = useState("");
  const [getPracticeArea, setGetPracticeArea] = useState([]);
  const [getCategories, setGetCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedPracticeArea, setSelectedPracticeArea] = useState(0);
  const [flagUser, setflagUser] = useState(false);
  const [fflagUser, setfflagUser] = useState(false);
  const [otpFirebase, setOtpFirebase] = useState("");
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  const [logincredential, setlogincredential] = useState({
    mobileNo: "",
  });
  const [getDocument, setGetDocument] = useState([
    { fileName: "", fileType: "", link: "", mimeType: "" },
  ]);
  const [getProfilePic, setGetProfilePic] = useState("");
  const [getAudioFilePath, setGetAudioFilePath] = useState("");
  const [getVideoFilePath, setGetVideoFilePath] = useState("");
  const [usercredential, setusercredential] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    profilePic: "",
  });
  const [expertcredential, setexpertcredential] = useState({
    category: "",
    practiceArea: "",
    bio: "",
    audioFilePath: "",
    videoFilePath: "",
    document: [
      {
        fileName: "",
        fileType: "",
        link: "",
        mimeType: "",
      },
    ],
    firstName: "",
    email: "",
    mobileNo: "",
    lastName: "",
    profilePic: "",
  });
  // const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [borhanApp, setBorhanApp] = useState(false);

  const [modalstateno, setmodalstateno] = useState(1);
  const [profileViewerState, setProfileViewerState] = useState(0);
  const [profileViewerModal, setProfileViewerModal] = useState(false);
  const [token, setToken] = useState("");
  const toggle = () => {
    setIsAuthModalOpen(false);
    setmodalstateno(1);
  };
  let recaptchaWrapperRef;

  const signinWithGoogleUsingFirebase = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        console.log("firebaseGoogle Result", result);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        console.log("firebasetokengoogle", token);
        // The signed-in user info.
        const user = result.user;
        console.log("usergooglefirebase", user);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const signinWithFacebookUsingFirebase = () => {
    const provider = new FacebookAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        console.log("facebook result", result);
        const user = result.user;
        console.log("userwithFacebookFire", user);
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        console.log("firebasefacebookcredentials", credential);
        // responseFacebook(user);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);

        // ...
      });
  };

  const ConfigureRecaptcha = async () => {
    // setLoading(true)
    // console.log("mdadf",mobileNo)
    window.recaptchaVerifier = await new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      auth
    );
  };
  const generateOtpUsingFirebase = async (e, mobileNo) => {
    // setLoading(true)

    e.preventDefault();
    if (recaptchaWrapperRef) {
      recaptchaWrapperRef.innerHTML = `<div id="sign-in-button"></div>`;
    }
    if (mobileNo.length < 10) {
      toast("Phone Number Invalid");
      // setLoading(false)
      return;
    }

    await ConfigureRecaptcha();

    let appVerifier = window.recaptchaVerifier;
    console.log(appVerifier, "appverifier");
    let number = "+" + mobileNo;
    console.log("mobile is ", number);
    signInWithPhoneNumber(auth, number, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        recaptchaWrapperRef.innerHTML = `<div id="sign-in-button"></div>`;
        appVerifier.clear();
        // setLoading(true);
        // window.recaptchaVerifier=null;
        if (modalstateno === 1) {
          setmodalstateno(2);
          console.log("this is second condition");
          // setLoading(false);
        } else if (modalstateno == 4) {
          setmodalstateno(7);
          // setLoading(false);
        } else if (modalstateno === 6) {
          setmodalstateno(8);
          // setLoading(false);

          // setLoading(false)
          toast("OTP sent successfully");
        }
        // ...
      })
      .catch((error) => {
        // setLoading(false)

        // Error; SMS not sent
        // window.recaptchaVerifier=null;
        console.log(error, "firebasegenerateotperror");
        recaptchaWrapperRef.innerHTML = `<div id="sign-in-button"></div>`;
        appVerifier.clear();
        toast("User Not Found");
        // alert("Otp you have entered is  wrong kindly re-enter the correct otp");
        // ...
      });
  };
  const verifyOtpUsingFirebase = async () => {
    // setLoading(true);
    let confirmationResult = window.confirmationResult;

    window.recaptchaVerifier = null;
    confirmationResult
      .confirm(otpFirebase)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log("otp firebase user mai kya hai", user);
        setFirebaseMobileUid(user?.uid);

        if (modalstateno == 2) {
          onSubmitLogin(user?.uid);
          // setLoading(false)
        } else if (modalstateno === 7) {
          onSubmitCreateBorhanUser(user?.uid);
          // setLoading(false)
        } else if (modalstateno === 8) {
          onSubmitCreateExpert(user?.uid);
          // setLoading(false)
        }
        // setLoading(false);
        // ...
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        console.log(error);
        toast("Otp you have entered is  wrong kindly re-enter the correct otp");
        //  setLoading(false);
      });
  };

  const responseFacebook = (response) => {
    console.log(response);
    let createData = {
      email: response.email,
      firstName: response.displayName.split(" ")[0],
      facebookId: response.uid,
      lastName: response.displayName.split(" ")[1],
      profilePic: response.photoURL ? response.photoURL : "",
      mobileNo: response.phoneNumber,
      isEmailVerified: response.emailVerified,
    };
    // console.log(createData, "ksdhcbsdbc")
    // loginAction.facebookLoginSignup(createData,(err,res)=>{
    //   if(err){
    //     alert("err")
    //   }else{
    //     alert("login success")
    //     window.location.reload();
    //     localStorage.setItem("token", res.data.token);
    //     }
    // })
  };
  const responseGoogleSuccess = async (response) => {
    console.log(response);
    let userInfo = {
      name: response.profileObj.name,
      emailId: response.profileObj.email,
    };
    let data = response.profileObj;
    console.log(data, "ksdhcbsdbc");
    loginAction.googleLoginSignup(data, (err, res) => {
      if (err) {
        alert("err");
      } else {
        alert("login success");
        window.location.reload();
        localStorage.setItem("token", res.data.token);
        setIsLoggedIn(true);
      }
    });
  };

  // Error Handler
  const responseGoogleError = (response) => {
    console.log(response);
  };

  // Logout Session and Update State
  const logout = (response) => {
    console.log(response);
    let userInfo = {
      name: "",
      emailId: "",
    };
    // this.setState({ userInfo, isLoggedIn: false });
  };
  useEffect(() => {
    // if (localStorage.getItem("token")||Agent.getLoginType()) {
    if (isLoggedIn === true) {
      console.log(Agent.getLoginType());
      setflagUser(true);
    }
  }, [isLoggedIn]);
  const fetchAllCategories = async () => {
    categoriesAction.fetchAllCategories((err, res) => {
      if (err) {
        console.log(err, "helllooo");
      } else {
        setGetCategories(res.data);
        console.log(res.data, " daata ");
      }
    });
  };

  const fetchAllPracticeArea = async () => {
    // setLoading(true)
    categoriesAction.fetchAllPracticeArea((err, res) => {
      if (err) {
      } else {
        setGetPracticeArea(res.data);
        // setLoading(false)
      }
    });
  };
  useEffect(() => {
    fetchAllCategories();
    fetchAllPracticeArea();
  }, []);
  useEffect(async () => {
    let data = await isSupported();
    if (data === true) {
      const messaging = getMessaging(app);
      console.log("isSupported", true);
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
        }
      });
      getToken(messaging, {
        vapidKey:
          "BKjkBjs0NF8cLaPAYNKFWiGSBcau-q3poapqeXZhbPUfBacozebEplWJBFIes8FioqhMbdpIzYmUzxTdgdrLxXk",
      })
        .then((currentToken) => {
          // console.log("this is token",currentToken)
          if (currentToken) {
            setToken(currentToken);

            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    } else {
      console.log("Browser Not Supported");
    }
  }, []);

  const onSubmitCreateBorhanUser = async (uid) => {
    // e.preventDefault();
    // setLoading(true);
    const { firstName, lastName, email, mobileNo, profilePic, otp } =
      usercredential;
    if (!firstName && !lastName && !email && !mobileNo && !otp) {
      alert("fill ");
      return;
    }
    const response = await fetch(
      `${config.BACKEND_URL}/admin/createborhanuser`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName,
          lastName,
          email,
          mobileNo,
          profilePic: getProfilePic,
          firebaseUid: uid,
          fireBaseToken: token,
          deviceType: "3",
        }),
      }
    );
    const json = await response.json();
    if (json.statusCode === 200) {
      localStorage.setItem("token", json.data);

      setIsAuthModalOpen(false);
      setProfileViewerModal(false);
      setmodalstateno(1);
      setIsLoggedIn(true);
      toast("User created Successfully");
      // setLoading(false)
      history("/userdashboard");
      window.location.reload();
    } else {
      toast(json.message);
      // setLoading(false)
    }
    // setLoading(false);
  };
  const onSubmitCreateExpert = async (uid) => {
    setLoading(true);

    const {
      category,
      practiceArea,
      bio,
      audioFilePath,
      videoFilePath,
      document,
      firstName,
      email,
      mobileNo,
      lastName,
      profilePic,
      otp,
    } = expertcredential;

    if (
      !firstName &&
      !lastName &&
      !email &&
      !mobileNo &&
      !otp &&
      !profilePic &&
      bio
    ) {
      alert("Please Fill All Details ");
      return;
    }
    // console.log("tokens" , token)
    let dataToSend = {
      firstName,
      lastName,
      email,
      mobileNo,
      profilePic: getProfilePic,
      document: getDocument,
      category: selectedCategory,
      practiceArea: selectedPracticeArea,
      bio,
      audioFilePath: getAudioFilePath,
      videoFilePath: getVideoFilePath,
      accountType: accountType,
      firebaseUid: uid,
      deviceType: "3",
      firebaseToken: token,
    };
    console.log("this is payload ", dataToSend);
    let response;
    await loginAction.CreateExpert(dataToSend, (err, res) => {
      if (err) {
        console.log(err, "here is error");
        toast(res.message);
        setLoading(false);
      } else {
        response = res;
        console.log(response, "here is res ");
        if (response.statusCode === 200) {
          // localStorage.setItem("token", response.data);
          // history("/userdashboard");
          toast("Expert Created Successfully");
          setIsAuthModalOpen(false);
          setProfileViewerModal(false);
          setmodalstateno(1);
          setLoading(false);
        }
      }
    });
  };
  const onSubmitLogin = async (uid) => {
    setLoading(true);
    let dataToSend = {
      mobileNo: logincredential.mobileNo,
      deviceType: "3",
      deviceToken: token,
      firebaseUid: uid,
    };
    if (!logincredential.mobileNo) {
      alert("fill ");
      return;
    }

    let json;
    loginAction.onLogin(dataToSend, (err, res) => {
      if (err) {
        console.log(err);
        toast(res.message);
        setLoading(false);
      } else {
        json = res;

        if (json.statusCode === 200) {
          setIsAuthModalOpen(false);
          setProfileViewerModal(false);
          setmodalstateno(1);
          localStorage.setItem("token", json.data);
          toast("Login successful");
          setIsLoggedIn(true);

          history("/userdashboard");
          setLoading(false);

          // window.location.reload();
        }
        if (err) {
          toast(res.message);
          setLoading(false);
        }
      }
    });
    //  setLoading(false);
  };
  let appleStore = () => {
    window.open(`${socialMediaLink.iosStoreLink}`, "_blank");
  };
  let playStore = () => {
    window.open(`${socialMediaLink.appPlayStoreLink}`, "_blank");
  };
  const uploadFilesUsingMulter = async (e, i) => {
    console.log("iiiihiihiohiin", "aloha0", e.target.files[0]);
    var formData = new FormData();
    formData.append("files", e.target.files[0]);
    const axiosRes = await axios({
      method: "post",
      url: `${config.BACKEND_URL}/admin/uploadfile`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    console.log(i, "idkkkkk", axiosRes);
    if (i === 1) {
      setGetProfilePic(axiosRes.data.data[0].path);
    } else if (i === 2) {
      setGetAudioFilePath(axiosRes.data.data[0].path);
      setAudioName(e.target.files[0].name);
    } else if (i === 3) {
      setGetVideoFilePath(axiosRes.data.data[0].path);
      setVideoName(e.target.files[0].name);
    } else if (i === 4) {
      setGetDocument([
        {
          fileName: axiosRes.data.data[0].filename,
          fileType: axiosRes.data.data[0].filename,
          link: axiosRes.data.data[0].path,
          mimeType: axiosRes.data.data[0].mimetype,
        },
      ]);
    }
  };

  const onChangeUser = (e) => {
    setusercredential({ ...usercredential, [e.target.name]: e.target.value });
    // console.log(e,'dcjhsdhjsdhbd');
  };
  const onChangeExpert = (e) => {
    setexpertcredential({
      ...expertcredential,
      [e.target.name]: e.target.value,
    });
  };
  const onChangeLogin = (e) => {
    setlogincredential({ ...logincredential, [e.target.name]: e.target.value });
  };
  const closeModal = () => {
    // resetModal();
    // setTimeout(() =>{resetModal();},1000)
  };

  const profileViewer = () => {
    if (flagUser) {
      history("/userdashboard");
      setIsAuthModalOpen(false);
    } else {
      setIsAuthModalOpen(true);
    }
  };
  const bookAnAppoitmentHeaderViewer = () => {
    // if(localStorage.getItem('token')!==null)
    if (isLoggedIn === true) {
      history("/expertlisting");
      setIsAuthModalOpen(false);
    } else {
      setIsAuthModalOpen(true);
    }
  };
  useEffect(() => {
    if (document.readyState === "complete") {
      isStickyNavbar();
    } else {
      window.addEventListener("load", isStickyNavbar);
      return () => {
        window.removeEventListener("load", isStickyNavbar);
      };
    }
  });

  const expertRoutes = () => {
    history("/expertlisting");
    window.location.reload();
  };

  /* Method that will fix header after a specific scrollable */
  const isStickyNavbar = () => {
    const header = document.querySelector(".navigation");
    const scrollTop = window.scrollY;
    if (
      location.pathname == "/userdashboard" ||
      location.pathname == "/videochat" ||
      location.pathname == "/appointmentdetails" ||
      location.pathname == "/favexperts" ||
      location.pathname == "/wallet" ||
      location.pathname == "/ChatSupport" ||
      location.pathname == "/managemembership"
    ) {
      setIsHeaderSticky(true);
    } else {
      setIsHeaderSticky(false);
    }
    // isHeaderSticky  ?  header.classList.add('admin-nav nav-shadow'):header.classList.remove('admin-nav nav-shadow') ;
  };
  return (
    <>
      {loading ? (
        <div className="custm-loader-modal">
          <TailSpin color="#000" height={200} width={200} />
        </div>
      ) : null}

      <ToastContainer />

      <div id="recaptcha-container" ref={(ref) => (recaptchaWrapperRef = ref)}>
        <div id="sign-in-button"></div>
      </div>
      <div
        className={`navigation ${isHeaderSticky ? "admin-nav nav-shadow" : ""}`}
      >
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img
                className="logo-img"
                src="/assets/img/main-logo.png"
                alt=""
              />
            </Link>
            {/* <Link className="login-out-nav-btn" to="javascript:;">
              Log in / Sign Up
            </Link> */}
            <button
              className="navbar-toggler"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              onClick={() => setToggleButton(!toggleButton)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`navbar-collapse collapse ${
                toggleButton ? "show" : ""
              }`}
              id="navbarNav"
            >
              <ul className="navbar-nav ms-auto">
                <li
                  className={`nav-item ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                >
                  <Link
                    className={`nav-link ${
                      location.pathname === "/" ? "active" : ""
                    }`}
                    aria-current="page"
                    to="/"
                    onClick={() => {
                      setToggleButton(!toggleButton);
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      location.pathname === "/expertlisting" ? "active" : ""
                    }`}
                    href="#legal"
                    
                  >
                    Legal Practice
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      location.pathname === "/membership" ? "active" : ""
                    }`}
                    href="#hwork"
                    
                  >
                    How It Works
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    
                    href="#testi"
                    className={`nav-link `}

                    // to="/privacypolicy"
                  >
                    Testimonials
                    
                  </a>
                </li>
                {!flagUser ? (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        location.pathname === "/userdashboard" ? "active" : ""
                      }`}
                      href="https://legal.borhans.com/"
                      // to="/userdashboard"
                      
                    >
                      Login
                    </a>
                  </li>
                ) : (
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        location.pathname === "/userdashboard" ? "active" : ""
                      }`}
                      // to="/userdashboard"
                      onClick={() => {
                        bookAnAppoitmentHeaderViewer();
  
                        setToggleButton(!toggleButton);
                      }}
                      href="#legal"
                    >
                      Profile
                    </a>
                  </li>
                )}
                <li className="nav-item">
                  <input
                    type="button"
                    value="Contact Us"
                    className="nav-link login-nav-btn"
                    
                  />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

      <Modal
        isOpen={isAuthModalOpen}
        toggle={() => {
          toggle();
        }}
        className="authentication-modal modal-dialog modal-dialog-centered modal-xl"
      >
        <div className="auth-modal-wrp">
          <div className="row">
            <div className="col-lg-6 p-0">
              <div className="auth-modal-artwork">
                <img
                  src="/assets/img/human-right-artwok.png"
                  className="img img-fluid"
                  alt=""
                />
              </div>
            </div>
            {modalstateno == 1 ? (
              <div className="col-lg-6 p-0">
                {loading ? (
                  <div className="custm-loader-modal">
                    <TailSpin color="#000" height={200} width={200} />
                  </div>
                ) : null}

                <div className="auth-modal-content">
                  <div className="w-100">
                    <div className="auth-modal-logo">
                      <img
                        src="/assets/img/main-logo.png"
                        className="img img-fluid"
                        alt=""
                      />
                    </div>
                    <h2>Please Sign in to Borhan</h2>
                    <div className="auth-input-wrp">
                      <label for="">Enter Mobile Number</label>
                      <PhoneInput
                        country={"om"}
                        type="text"
                        name="mobileNo"
                        value={logincredential.mobileNo}
                        onChange={(phone) => {
                          setlogincredential({
                            ...logincredential,
                            ["mobileNo"]: phone,
                          });
                        }}
                        placeholder="Enter your Mobile Number"
                      />
                      <button
                        role="button"
                        data-bs-target="#verifyOTPmodal"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        className="btn auth-main-btn"
                        type="button"
                        onClick={(e) => {
                          generateOtpUsingFirebase(e, logincredential.mobileNo);
                        }}
                      >
                        Send OTP
                      </button>
                    </div>
                    <ul>
                      <li className="pe-2"></li>
                      <li className="ps-2"></li>
                    </ul>
                    <h5>
                      Don’t have account ?{" "}
                      <a
                        role="button"
                        data-bs-target="#createAccmodal"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        href="javascript:;"
                        onClick={() => {
                          setmodalstateno(3);
                        }}
                      >
                        Create now
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}
            {modalstateno == 2 ? (
              <div className="col-lg-6 p-0">
                <div className="auth-modal-content">
                  <div className="w-100">
                    <div className="auth-modal-logo">
                      <img
                        src="/assets/img/main-logo.png"
                        className="img img-fluid"
                        alt=""
                      />
                    </div>
                    <h2>Please Sign in to Borhan</h2>
                    <div className="auth-input-wrp">
                      <label for="">
                        Please enter the OTP sent to{" "}
                        <span>{logincredential.mobileNo}</span>
                      </label>

                      <input
                        type="text"
                        name="otp"
                        onChange={(e) => {
                          setOtpFirebase(e.target.value);
                        }}
                        className="form-control"
                        placeholder=""
                      />
                      <button
                        className="btn auth-main-btn"
                        type="submit"
                        onClick={() => {
                          verifyOtpUsingFirebase();
                        }}
                      >
                        Verify
                        {/* {loading?                
                         <div class="spinner-border text-light" role="status">
                          <span class="sr-only">Loading...</span>
                         </div>:""} */}
                      </button>
                    </div>
                    <h5>
                      Not Received your code ?{" "}
                      <a
                        onClick={(e) =>
                          generateOtpUsingFirebase(e, logincredential.mobileNo)
                        }
                      >
                        {" "}
                        Resend code
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}

            {modalstateno == 3 ? (
              <div className="col-lg-6 p-0">
                <div className="auth-modal-content">
                  <div className="w-100">
                    <div className="auth-modal-logo">
                      <img
                        src="/assets/img/main-logo.png"
                        className="img img-fluid"
                        alt=""
                      />
                    </div>
                    <h2>Get Started</h2>
                    <div className="get-started-buttons">
                      <div>
                        <button
                          className="btn"
                          type="button"
                          onClick={() => {
                            setmodalstateno(4);
                          }}
                        >
                          Borhan User
                        </button>
                      </div>
                      <div>
                        <button
                          className="btn"
                          type="button"
                          onClick={() => {
                            setmodalstateno(5);
                          }}
                        >
                          Expert/Freelancer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {modalstateno == 4 ? (
              <div className="col-lg-6 p-0">
                <div className="auth-modal-content">
                  {loading ? (
                    <div className="custm-loader-modal">
                      <TailSpin color="#000" height={200} width={200} />
                    </div>
                  ) : null}
                  {/* {loading ? (
                <div className="custm-loader">
                  <TailSpin color="#000000" height={200} width={200} />
                </div>
              ) : null} */}
                  <div className="w-100">
                    <div className="auth-profile-pic-wrp">
                      <div className="profile-pic-chooose">
                        <img
                          src={`${
                            getProfilePic == ""
                              ? "/assets/img/profile-picture-icon.png"
                              : getProfilePic
                          }`}
                          className="img img-fluid"
                          alt=""
                        />
                        <h6>Profile Picture</h6>
                        <input
                          name="profilePic"
                          className="hide-input"
                          type="file"
                          accept="image/png,image/jpeg,image/jpg"
                          onChange={(e) => {
                            uploadFilesUsingMulter(e, 1);
                          }}
                        />
                      </div>
                    </div>
                    <div className="auth-input-wrp">
                      <div className="row">
                        <div className="col-lg-6">
                          <label for="">First Name</label>
                          <input
                            type="text"
                            onChange={onChangeUser}
                            className="form-control"
                            name="firstName"
                            placeholder=""
                          />
                        </div>
                        <div className="col-lg-6">
                          <label for="">Last Name</label>
                          <input
                            type="text"
                            onChange={onChangeUser}
                            className="form-control"
                            name="lastName"
                            placeholder=""
                          />
                        </div>
                        <div className="col-lg-12">
                          <label for="">Email ID</label>
                          <input
                            type="email"
                            className="form-control"
                            onChange={onChangeUser}
                            name="email"
                            placeholder=""
                          />
                        </div>
                        <div className="col-lg-12">
                          <label for="">Mobile No.</label>
                          {/* <input
                            type="string"
                            className="form-control"
                            name="mobileNo"
                            onChange={onChangeUser}
                            placeholder=""
                            maxlength="10"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          /> */}
                          <PhoneInput
                            country={"om"}
                            type="text"
                            name="mobileNo"
                            value={usercredential.mobileNo}
                            onChange={(phone) => {
                              setusercredential({
                                ...usercredential,
                                ["mobileNo"]: phone,
                              });
                              // setusercredential({ ...usercredential, [e.target.name]: e.target.value });

                              // console.log("kya hai phone mai ", phone, "   ", logincredential.mobileNo);
                            }}
                            // className="form-control"
                            placeholder="Enter your Mobile Number"
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn auth-main-btn"
                        onClick={(e) => {
                          generateOtpUsingFirebase(e, usercredential.mobileNo);
                        }}
                      >
                        Create Account
                      </button>
                    </div>
                    <h5>
                      By signing up , you agree to{" "}
                      <a href="javascript:;">terms and condition</a> and Borhan{" "}
                      <a href="javascript:;">policy</a>
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}

            {modalstateno == 5 ? (
              <div className="col-lg-6 p-0">
                <div className="auth-modal-content">
                  <div className="w-100">
                    <div className="auth-profile-pic-wrp">
                      <div className="profile-pic-chooose">
                        <img
                          src={`${
                            getProfilePic == ""
                              ? "/assets/img/profile-picture-icon.png"
                              : getProfilePic
                          }`}
                          className="img img-fluid"
                          alt=""
                        />
                        <h6>Profile Picture</h6>
                        <input
                          name="profilePic"
                          className="hide-input"
                          type="file"
                          accept="image/png,image/jpeg,image/jpg"
                          onChange={(e) => {
                            uploadFilesUsingMulter(e, 1);
                          }}
                        />
                      </div>
                    </div>
                    <div className="auth-input-wrp">
                      <div className="row">
                        <div className="col-lg-6">
                          <label for="">First Name</label>
                          <input
                            type="text"
                            name="firstName"
                            onChange={onChangeExpert}
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                        <div className="col-lg-6">
                          <label for="">Last Name</label>
                          <input
                            type="text"
                            name="lastName"
                            onChange={onChangeExpert}
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                        <div className="col-lg-12">
                          <label for="">Email ID</label>
                          <input
                            type="email"
                            name="email"
                            onChange={onChangeExpert}
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                        <div className="col-lg-12">
                          <label for="">Mobile No.</label>

                          <PhoneInput
                            country={"om"}
                            type="text"
                            name="mobileNo"
                            value={expertcredential.mobileNo}
                            onChange={(phone) => {
                              setexpertcredential({
                                ...expertcredential,
                                ["mobileNo"]: phone,
                              });
                            }}
                            placeholder="Enter your Mobile Number"
                          />
                        </div>
                        <div className="col-lg-12">
                          <label for="">Account Type</label>
                          <div className="gender-buttons">
                            {/* <!-- add active class for active the tab --> */}
                            <button
                              className={`btn me-2 ${
                                accountType === "expert" ? "active" : ""
                              }`}
                              type="button"
                              onClick={() => {
                                setaccountType("expert");
                              }}
                            >
                              Expert
                            </button>
                            <button
                              className={`btn ms-2 ${
                                accountType === "freelancer" ? "active" : ""
                              } `}
                              type="button"
                              onClick={() => {
                                setaccountType("freelancer");
                              }}
                            >
                              Freelancer
                            </button>
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn auth-main-btn"
                        type="button"
                        onClick={() => {
                          setmodalstateno(6);
                        }}
                      >
                        Continue
                      </button>
                    </div>
                    <h5>
                      By signing up , you agree to{" "}
                      <a href="javascript:;">terms and condition</a> and Borhan{" "}
                      <a href="javascript:;">policy</a>
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}

            {modalstateno == 6 ? (
              <div className="col-lg-6 p-0">
                <div className="auth-modal-content">
                  <div className="w-100">
                    <div className="auth-input-wrp">
                      <div className="row">
                        <div class="col-lg-6">
                          <label>Category</label>
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setSelectedCategory(e.target.value);
                            }}
                          >
                            <option selected value="0">
                              Select
                            </option>

                            {getCategories &&
                              getCategories.map((obj, index) => {
                                return (
                                  <option value={`${obj._id}`}>
                                    {obj.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div class="col-lg-6">
                          <label>Pratice Area</label>
                          <select
                            class="form-control"
                            onChange={(e) => {
                              setSelectedPracticeArea(e.target.value);
                            }}
                          >
                            <option selected value="0">
                              Select
                            </option>
                            {getPracticeArea &&
                              getPracticeArea.map((obj, index) => {
                                return (
                                  <option value={`${obj._id}`}>
                                    {obj.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="col-lg-12">
                          <label for="">Bio</label>
                          <input
                            type="text"
                            name="bio"
                            maxLength="50"
                            onChange={onChangeExpert}
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                        <div className="col-lg-12 pt-2">
                          <label for="">
                            You can Record Audio and Video (Optional )
                          </label>
                          <div className="record-buttons">
                            <div className="btn-audio">
                              <button className="btn" type="button">
                                <img
                                  src="/assets/img/audio-record-icon.png"
                                  className="img img-fluid"
                                  alt=""
                                />
                                Audio record
                              </button>
                              <input
                                type="file"
                                name="audio"
                                onChange={(e) => uploadFilesUsingMulter(e, 2)}
                                className="form-input-file audio-file"
                              />{" "}
                              <div className=" display-value">{audioName}</div>
                            </div>
                            <div className="btn-video">
                              <button className="btn" type="button">
                                <img
                                  src="/assets/img/video-record-icon.png"
                                  className="img img-fluid"
                                  alt=""
                                />
                                Video record
                              </button>
                              <input
                                type="file"
                                name="video"
                                onChange={(e) => uploadFilesUsingMulter(e, 3)}
                                className="form-input-file video-file"
                              />{" "}
                              <div className=" display-value">{videoName}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 pt-2">
                          <label for="">Upload Documents</label>
                          <div className="upload-doc-field">
                            <input
                              type="file"
                              name="document"
                              accept="image/png,image/jpeg,image/jpg"
                              onChange={(e) => uploadFilesUsingMulter(e, 4)}
                              className="form-input-file"
                            />
                            <div className="artifical-doc-feild">
                              <img
                                src="/assets/img/upload-document-icon.png"
                                className="img img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <a href="javascript:;">Documents List</a>
                        </div>
                      </div>
                      <button
                        className="btn auth-main-btn"
                        type="submit"
                        onClick={(e) => {
                          generateOtpUsingFirebase(
                            e,
                            expertcredential.mobileNo
                          );
                        }}
                      >
                        Create Account
                      </button>
                    </div>
                    <h5>
                      By signing up , you agree to{" "}
                      <a href="javascript:;">terms and condition</a> and Borhan{" "}
                      <a href="javascript:;">policy</a>
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}
            {modalstateno == 7 ? (
              <div className="col-lg-6 p-0">
                <div className="auth-modal-content">
                  <div className="w-100">
                    <div className="auth-modal-logo">
                      <img
                        src="/assets/img/main-logo.png"
                        className="img img-fluid"
                        alt=""
                      />
                    </div>
                    <h2>Please Sign up to Borhan</h2>
                    <div className="auth-input-wrp">
                      <label for="">
                        Please enter the OTP sent to{" "}
                        <span>{usercredential.mobileNo}</span>
                      </label>
                      <input
                        type="text"
                        name="otp"
                        onChange={(e) => {
                          setOtpFirebase(e.target.value);
                        }}
                        className="form-control"
                        placeholder=""
                      />

                      <button
                        className="btn auth-main-btn"
                        type="submit"
                        onClick={() => {
                          verifyOtpUsingFirebase();
                        }}
                      >
                        Verify
                      </button>
                    </div>
                    <h5>
                      Not Received your code ?{" "}
                      <a
                        onClick={(e) =>
                          generateOtpUsingFirebase(e, usercredential.mobileNo)
                        }
                      >
                        {" "}
                        Resend code
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}
            {modalstateno == 8 ? (
              <div className="col-lg-6 p-0">
                <div className="auth-modal-content">
                  <div className="w-100">
                    <div className="auth-modal-logo">
                      <img
                        src="/assets/img/main-logo.png"
                        className="img img-fluid"
                        alt=""
                      />
                    </div>
                    <h2>Please Sign in to Borhan</h2>
                    <div className="auth-input-wrp">
                      <label for="">
                        Please enter the OTP sent to{" "}
                        <span>{expertcredential.mobileNo}</span>
                      </label>
                      <input
                        type="text"
                        name="otp"
                        onChange={(e) => {
                          setOtpFirebase(e.target.value);
                        }}
                        className="form-control"
                        placeholder=""
                      />

                      <button
                        className="btn auth-main-btn"
                        type="submit"
                        onClick={() => {
                          verifyOtpUsingFirebase();
                        }}
                      >
                        Verify
                      </button>
                    </div>
                    <h5>
                      Not Received your code ?{" "}
                      <a
                        onClick={(e) =>
                          generateOtpUsingFirebase(e, expertcredential.mobileNo)
                        }
                      >
                        {" "}
                        Resend code
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={borhanApp}
        toggle={() => {
          setBorhanApp(false);
        }}
        className="authentication-modal getapp modal-dialog modal-dialog-centered modal-xl"
      >
        <div class="auth-modal-wrp">
          <div class="row">
            <div class="col-xl-6 col-lg-12 col-md-12 p-5 text-center-md">
              <div class="logo-img text-center">
                <img
                  src="/assets/img/favicon.png"
                  class="img-fluid text-center"
                />
              </div>
              <h4 class="mt-5 download-app-tagline pb-3">
                Download Borhan App
              </h4>
              <h6 class="pt-2"> Easy, Smart, Useful</h6>
              <p class="mt-t">
                <small class="text-muted">
                  Connect to experts at your fingertips
                </small>
              </p>
              <div class="row get-app-links ">
                <div class="col-lg-6 mt-3">
                  <a onClick={playStore}>
                    <img
                      src="/assets/img/google-play-store-btn.png"
                      class="img-fluid"
                    />
                  </a>
                </div>
                <div class="col-lg-6 mt-3">
                  <a onClick={appleStore}>
                    <img
                      src="/assets/img/app-store-btn.png"
                      class="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xl-6   p-0 get-app-banner">
              <div id="container text-center">
                <img src="/assets/img/get-app-banner.png" />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Header;
