import { React, useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import Footer from "./Footer";
import Calendar from "react-calendar";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import expextAction from "../../actions/expertlisting.action";
import "react-calendar/dist/Calendar.css";
import NewsletterSubscribed from "./NewsletterSubscribed";
import moment from "moment";
import { isDisabled } from "@testing-library/user-event/dist/utils";
// import videopng from "/assets/img/video-call-icon.png";
// import audiopng from "/assets/img/audio-call-icon.png";
// import chatpng from "/assets/img/chat-icon.png";
import appointmentAction from "../../actions/appointment.action";
import categoriesAction from "../../actions/categories.action";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TailSpin } from "react-loader-spinner";
import homeAction from "../../actions/home.action";

const ExpProfile = (props) => {
  const [mainLoader, setMainLoader] = useState(true);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [expPriceDetails, setExpPriceDetails] = useState([]);
  const [bookappointmentmodal, setbookappointmentmodal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [scheduleappointmentmodal, setscheduleappointmentmodal] =
    useState(false);
  const [expert, setExpert] = useState(null);
  const [typeCall, setTypeCall] = useState(1);
  const [categorie, setCategorie] = useState("");
  const [practiceArea, setPracticeArea] = useState("");
  const [Duration, setDuration] = useState(null);
  const [question, SetQuestion] = useState("");
  const [startAppointmentTime, SetStartAppointmentTime] = useState(null);
  const [appointmentTime, SetAppointmentTime] = useState("");
  const [timeList, setTimeList] = useState([]);
  const [endAppointmentTime, SetEndAppointmentTime] = useState(null);
  const [timeSlotId, setTimeSlotId] = useState("");
  const [dateSlot, SetDateSlot] = useState(null);
  const [getPracticeArea, setGetPracticeArea] = useState([]);
  const [selectedTime, setSelectedTime] = useState([]);
  const [isTimeSelected, setIsTimeSelected] = useState(false);
  const history = useNavigate();
  const [appointmentType, setAppointmentType] = useState(null);
  const [category, setCategorieName] = useState("");
  const [practiceAreas, setPracticeAreas] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [amountToPay, setAmountToPay] = useState(0);
  const [amountCharged, setAmountCharged] = useState(0);
  const [isPaid, setIsPaid] = useState(true);
  const [appointmentId, setAppointmentId] = useState("");
  let finalArray = [];
  if (window.location.search) {
    let array = window.location.search.split("&appointmentId=");

    array[0] = array[0].replace("?planId=", "");
    let newArray = array[1].split("&amount=");

    finalArray = [array[0], newArray].flat();
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 10,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    console.log("this is params", params);
    setCategorieName(params.category);
    setPracticeAreas(params.practiceArea);
    // console.log()
    fetchExpert();
    scrollToTop();
    getExpertPriceDetails();

    getExpertPricingDetailsAccordingToCallType("video");
    if (finalArray.length === 3) {
      updateUserOneTimePayment();
    }
  }, []);
  useEffect(() => {
    fetchTime();
  }, [dateSlot, Duration]);
  //moment().format('MMMM Do YYYY, h:mm:ss a');
  const setinterval1 = (e) => {
    let start = moment.utc(e.startAppointmentTime).local().format("HH:mm");
    let end = moment.utc(e.endAppointmentTime).local().format("HH:mm");

    console.log(start + "-" + end);
    return start + "-" + end;
  };
  const fetchTime = () => {
    console.log(Duration, dateSlot, "sdclkndcs");
    let id = params.id;

    if (!Duration && !dateSlot) {
      return;
    }
    let payload = {
      expertId: id,
      appointmentDate: dateSlot,
      duration: Duration,
    };
    expextAction.getAvailableTimeForUser(payload, (err, res) => {
      if (err) {
      } else {
        console.log(res);
        setTimeList(res.data);
      }
    });
  };
  const Datehandel = (e) => {
    // api hit

    e = moment(e).format("YYYY-MM-DD");
    console.log(e, "date to selected");
    SetDateSlot(e);
    // fetchTime();
  };
  const onSelectTime = (start, end) => {
    // e=moment(e).format('YYYY-MM-DD');
    // console.log(e,"date to selected")
    // console.log(e)
    if (dateSlot) {
      toast("time slot selected");
      if (1) {
        let localdate = dateSlot + " " + start;
        console.log(localdate, "here is ");
        console.log(moment(localdate), "locaDate");
        console.log(moment.utc(moment(localdate)).format(), "start time");
        SetStartAppointmentTime(start);

        let afterSomeMinutes = dateSlot + " " + end;
        console.log(afterSomeMinutes, end, "here is ");
        console.log(moment(afterSomeMinutes), "locaDate");
        console.log(moment.utc(moment(afterSomeMinutes)).format(), "end time");
        SetEndAppointmentTime(end);
      }
    }
  };
  const fetchExpert = async () => {
    setMainLoader(true);
    let id = params.id;
    await expextAction.getSingleExport(id, (err, res) => {
      if (err) {
      } else {
        // console.log(res,'')
        setExpert(res.data);
        console.log(expert);
      }
      setMainLoader(false);
    });
  };
  const getExpertPriceDetails = async () => {
    homeAction.getExpertPriceDetails(params.id, (err, res) => {
      if (err) {
        toast(err.message);
      } else {
        setExpPriceDetails(res.data.priceDetails);
      }
    });
  };

  const getExpertPricingDetailsAccordingToCallType = async (callTypeToSend) => {
    let dataToSend = { expertId: params.id, callType: callTypeToSend };
    homeAction.getExpertPricingDetailsAccordingToCallType(
      dataToSend,
      (err, res) => {
        if (err) {
          toast(err.message);
        } else {
          console.log("response", res);
          setWalletBalance(res.data.balanceToSend);
        }
      }
    );
  };
  const bookAppointment = (data) => {
    let id = params.id;
    setMainLoader(true);
    let calltype = "";
    if (typeCall == 1) {
      calltype = "video";
    } else if (typeCall == 2) {
      calltype = "audio";
    } else {
      return;
    }
    let dataToSend = {
      duration: Duration,
      timeSlotId: timeSlotId,
      appointmentTime: startAppointmentTime,
      appointmentDate: dateSlot,
      practiceArea: "625fef06f962a2761cd19e74",
      expertId: id,
      appointmentType: calltype,
      appointDateandTime: startAppointmentTime,
      status: "pending",
      startAppointmentTime: startAppointmentTime,
      endAppointmentTime: endAppointmentTime,
      isPaid: data,
    };
    console.log(dataToSend, "data playload");
    expextAction.bookAnAppoitment(dataToSend, (err, res) => {
      if (err) {
        toast(res.message);
        setMainLoader(false);
      } else {
        if (res.data.isPaid === true) {
          toast(" Successfull appointment ");
          setMainLoader(false);
          setTimeout(() => {
            history("/appointmentDetails");
          }, 100);
        } else {
          setAppointmentId(res.data._id);
        }
      }
    });
  };
  const handlemodal = () => {
    let id = params.id;
    let playload = {
      expertId: id,
      question: question,
    };
    setMainLoader(true);
    appointmentAction.bookChatAppointment(playload, (err, res) => {
      if (err) {
      } else {
        // toast("Successfull appointment call ");
        if (res.data.status === "confirmed") {
          history(`/room/${res.data._id}`);
        } else if (res.data.status === "pending") {
          toast("Chat Room request has been sent successfully ");
        }
        // console.log(res,"here is resposnse in exprofile.js")
      }
      setMainLoader(false);
    });
  };
  const newHandle = () => {
    if (typeCall === 3) {
      setbookappointmentmodal(true);
      return;
    } else {
      setscheduleappointmentmodal(true);
    }
  };

  const getAmountCharged = async () => {
    let callTypeToSend;
    if (typeCall === 1) {
      callTypeToSend = "video";
    } else if (typeCall === 2) {
      callTypeToSend = "audio";
    } else if (typeCall === 3) {
      callTypeToSend = "chat";
    }
    let dataToSend = {
      expertId: params.id,
      callType: callTypeToSend,
      callMinutes: Duration,
    };

    homeAction.getAmountCharged(
      dataToSend,

      (err, res) => {
        if (err) {
          toast(err.message);
        } else {
          if (res.data.amountCharged === 0) {
            bookAppointment(true);
            setscheduleappointmentmodal(false);
            setbookappointmentmodal(false);
          } else {
            setAmountCharged(res.data.amountCharged);
            amountToPayForAppointmentBooking(res.data.amountCharged);
          }
        }
      }
    );
  };

  const getExpertChatCharges = async () => {
    let dataToSend = { expertId: params.id };
    homeAction.getExpertChatCharges(dataToSend, (err, res) => {
      if (err) {
        toast(err.message);
      } else {
        homeAction.amountToPayForAppointmentBooking(
          { amount: res.data },
          (error, response) => {
            if (error) {
              toast(error.message);
            } else {
              handlemodal();
            }
          }
        );
      }
    });
  };

  const amountToPayForAppointmentBooking = async (amount) => {
    let dataToSend = {
      amount: amount,
    };

    homeAction.amountToPayForAppointmentBooking(dataToSend, (err, res) => {
      if (err) {
        toast(err.message);
      } else {
        if (res.data.amountToPay === 0) {
          bookAppointment(true);
          setscheduleappointmentmodal(false);
          setbookappointmentmodal(false);
        } else {
          setAmountToPay(res.data.amountToPay);
          setPaymentModal(true);
          bookAppointment(false);

          setscheduleappointmentmodal(false);
          setbookappointmentmodal(false);
        }
      }
    });
  };

  const addUserOneTimePayment = async () => {
    setMainLoader(true);
    setPaymentModal(false);
    let planNameToSend;
    if (typeCall === 1) {
      planNameToSend = "video" + Duration;
    } else if (typeCall === 2) {
      planNameToSend = "audio" + Duration;
    } else if (typeCall === 3) {
      planNameToSend = "chat" + Duration;
    }

    let dataToSend = {
      amount: amountToPay,
      planName: planNameToSend,
      successUrl: window.location.href,
      amountInUpdateOtpApi: amountCharged,
      appointmentId,
    };

    homeAction.addUserOneTimePayment(dataToSend, (err, res) => {
      if (err) {
        toast(err.message);
      } else {
        console.log("dataToSend", res);
        window.location.replace(res.data);
      }
    });
  };

  const updateUserOneTimePayment = async () => {
    let dataToSend = {
      planId: finalArray[0],

      appointmentId: finalArray[1],
      amount: parseFloat(finalArray[2]),
    };

    homeAction.updateUserOneTimePayment(dataToSend, (err, res) => {
      if (err) {
        toast(err.message);
      } else {
        if (res.data.isPaid === true) {
          toast(" Successfull appointment ");
          setMainLoader(false);
          setTimeout(() => {
            history("/appointmentDetails");
          }, 100);
        }
      }
    });
  };
  const handlemodal1 = async () => {
    if (startAppointmentTime == null || dateSlot == null) {
      toast("Please Select Appointment Date");
      return;
    }
    getAmountCharged();
  };

  return (
    <>
      <ToastContainer />
      {mainLoader ? (
        <div className="custm-loader">
          <TailSpin color="#000" height={200} width={200} />
        </div>
      ) : null}

      <section className="breadcrumb-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="breadcrumb-content">
                <h1>Experts</h1>
                <ul>
                  <li>
                    <a href="javascript:;">Home</a>{" "}
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li>
                    <a href="javascript:;">{category}</a>{" "}
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li>
                    <a href="javascript:;">{practiceAreas}</a>{" "}
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </li>
                  <li className="active">
                    <a href="javascript:;">Expert Listing</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {expert != null ? (
        <section className="exp-profile-wrp">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <div className="exp-profile-box">
                  <div className="exp-profile-detail-row">
                    <ul>
                      <li>
                        <div className="exp-user-dt">
                          <div>
                            {console.log("this is exp", expert)}
                            <img
                              src={`${
                                expert.document[0].link === ""
                                  ? "/assets/img/expert-thumb.png"
                                  : expert.document[0].link
                              }`}
                              className="img img-fluid"
                              alt=""
                            />
                            {/* <img src="/assets/img/pratice-area-thumb.png" className="img img-fluid" alt=""/> */}
                          </div>
                          <div>
                            {console.log("this is profile", expert)}
                            <h4>
                              {expert?.userId && expert?.userId?.firstName}{" "}
                              {expert?.userId?.lastName}{" "}
                            </h4>
                            <p>{expert?.practiceArea[0].name}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div>
                          <h4>{expert.experience}+</h4>
                          <p>Years of Experience</p>
                        </div>
                      </li>
                      <li>
                        <div>
                          <h4>
                            {(
                              Math.round(expert.rating.avgRating * 100) / 100
                            ).toFixed(2)}{" "}
                            <span className="fa fa-star checked"></span> Rating
                          </h4>
                          {/* <div className="star-rating-text">
                                    <div className="star-rating">
                                       <span className="fa fa-star checked"></span>
                                       <span className="fa fa-star checked"></span>
                                       <span className="fa fa-star checked"></span>
                                       <span className="fa fa-star checked"></span>
                                    </div>
                                    <p>Rating</p>
                                 </div>  */}
                        </div>
                      </li>
                      <li>
                        <div>
                          <h4>{expert.noOfSessionsDone} h</h4>
                          <p>Session done</p>
                        </div>
                      </li>
                      <li>
                        <div className="exp-intro">
                          {/* <img src='assets/img/video-call-icon.png' className="img img-fluid" alt=""/> */}
                        </div>
                        <div>
                          <div>
                            <p>{expert.bio}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="exp-communicate-btn-row">
                    <div>
                      <button
                        className={`btn  ${
                          typeCall == 1 ? "bg-dark text-white" : ""
                        }`}
                        type="button "
                        onClick={(e) => {
                          e.preventDefault();
                          setTypeCall(1);
                          getExpertPricingDetailsAccordingToCallType("video");
                        }}
                      >
                        <img
                          src="assets/img/video-call-icon.png"
                          className="img img-fluid"
                          alt=""
                        />
                        Video Call
                      </button>
                    </div>
                    <div>
                      <button
                        className={`btn  ${
                          typeCall == 2 ? "bg-dark text-white" : ""
                        }`}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setTypeCall(2);
                          getExpertPricingDetailsAccordingToCallType("audio");
                        }}
                      >
                        <img
                          src="/assets/img/audio-call-icon.png"
                          className="img img-fluid"
                          alt=""
                        />{" "}
                        Audio Call
                      </button>
                    </div>
                    <div>
                      <button
                        className={`btn  ${
                          typeCall == 3 ? "bg-dark text-white" : ""
                        }`}
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          setTypeCall(3);
                          getExpertPricingDetailsAccordingToCallType("chat");
                        }}
                      >
                        <img
                          src="/assets/img/chat-icon.png"
                          className="img img-fluid"
                          alt=""
                        />{" "}
                        Chat
                      </button>
                    </div>
                  </div>
                  {expPriceDetails && expPriceDetails.length > 0 ? (
                    <div className="continue-booking-btn">
                      <button
                        className="btn"
                        onClick={(e) => {
                          e.preventDefault();
                          newHandle();
                        }}
                        type="button"
                      >
                        Continue to Book Appointment{" "}
                      </button>
                    </div>
                  ) : (
                    <div className="continue-booking-btn">
                      <button
                        className="btn"
                        // onClick={(e) => {
                        //   e.preventDefault();
                        //   newHandle();
                        // }}
                        type="button"
                      >
                        No Price Set Yet{" "}
                      </button>
                    </div>
                  )}

                  <div className="exp-wallet-amount">
                    <p>
                      Borhan Wallet Amount -{" "}
                      <a href="javascript:;">
                        {walletBalance} {typeCall === 3 ? "SMS" : "Minutes"}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
      <section className="newsletter-wrp">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="newsletter-feild-box">
                <form>
                  <div className="position-relative">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter your email address....."
                    />
                    <button className="btn" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="newsletter-content">
                <div className="">
                  <h1>Newsletter</h1>
                  <p>
                    Be the first to know about exciting new offers and special
                    events and much more.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Modal
        className="authentication-modal modal-dialog modal-dialog-centered modal-xl"
        isOpen={scheduleappointmentmodal}
        toggle={() => {
          setscheduleappointmentmodal(false);
          setbookappointmentmodal(false);
        }}
      >
        <div className="modal-content">
          <div className="modal-body">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setbookappointmentmodal(false);
                setscheduleappointmentmodal(false);
              }}
            ></button>
            <div className="auth-modal-wrp">
              <div className="row">
                <div className="col-lg-12">
                  <div className="auth-modal-content">
                    <div className="w-100">
                      <div className="book-appointment-box">
                        <h1>Schedule an Appointment</h1>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="book-app-box">
                              <div className="schedule-calendar">
                                <Calendar
                                  minDate={new Date()}
                                  onClick={(e) => {
                                    // e.preventDefault();

                                    console.log(e);
                                  }}
                                  onChange={(e) => {
                                    // e.preventDefault()
                                    Datehandel(e);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="book-app-box">
                              <h1> Appointment Time</h1>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  {/* <label for="">Select call Duration time</label> */}
                                  <select
                                    className="form-select "
                                    aria-label="example"
                                    onChange={(e) => {
                                      console.log(e.target.value);
                                      setDuration(e.target.value);
                                    }}
                                    // value={Duration}
                                  >
                                    <option selected value={0}>
                                      Select Duration
                                    </option>
                                    <option value={15}>15 Mintues</option>
                                    <option value={30}>30 Mintues</option>
                                    <option value={45}>45 Mintues</option>
                                    <option value={60}>60 Mintues</option>
                                  </select>
                                </div>
                              </div>
                              <div className="select-time-slot text-center mt-2">
                                {/* <img src="/assets/img/time-slot.png" className="img img-fluid" alt=""/> */}
                                <div className="row ">
                                  {timeList.length > 0 ? (
                                    timeList.map((e, index) => {
                                      return (
                                        <div className="col-lg-6 btn-appointment1 ">
                                          {e.isAvailable ? (
                                            <button
                                              className={`btn ${
                                                isTimeSelected
                                                  ? timeSlotId == e._id
                                                    ? "btn-dark"
                                                    : ""
                                                  : "btn-white bg-white"
                                              }  btn-appointment`}
                                              onClick={(val) => {
                                                val.preventDefault();
                                                onSelectTime(
                                                  e.startAppointmentTime,
                                                  e.endAppointmentTime
                                                );
                                                setTimeSlotId(e._id);
                                                setIsTimeSelected(true);
                                                // toast(e._id,"selected time id")
                                              }}
                                            >
                                              {setinterval1(e)}
                                            </button>
                                          ) : (
                                            <button
                                              className="btn btn-white bg-white btn-appointment"
                                              disabled
                                            >
                                              {setinterval1(e)}
                                            </button>
                                          )}
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div text-algin="center">
                                      No Slot Available
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="continue-booking-btn p-0 mb-4">
                        <button
                          className="btn"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();

                            handlemodal1();
                          }}
                        >
                          {" "}
                          Proceed and Pay{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className="authentication-modal modal-dialog modal-dialog-centered modal-xl"
        isOpen={bookappointmentmodal}
        toggle={() => {
          setbookappointmentmodal(false);
        }}
      >
        <ModalBody>
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setbookappointmentmodal(false)}
              ></button>
              <div className="auth-modal-wrp">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="auth-modal-content">
                      <div className="w-100">
                        {/* <div className="book-appointment-box">
                                    <h1>Book an Appointment</h1>
                                    <div className="book-app-box">
                                       <div className="row">
                                          <div className="col-lg-4">
                                             <div className="form-group">
                                                <label for="">Select Appointment type</label>
                                                <select className="form-select" aria-label="example" onChange={e=>{
                                                   setTypeCall(e.target.value)
                                                }}>
                                                   <option selected value="Video Call">Video Call</option>
                                                   <option value="Face to Face">Face to Face</option>
                                                   <option value="Audio Call">Audio Call</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div className="col-lg-4">
                                             <div className="form-group">
                                                <label for="">Select call Duration time</label>
                                                <select className="form-select " aria-label="example" onChange={e=>{
                                                   console.log(e.target.value);
                                                   setDuration(e.target.value);
                                                }}>
                                                   <option selected value={15}>15 Mintues</option>
                                                   <option value={30}>30 Mintues</option>
                                                   <option value={45}>45 Mintues</option>
                                                   <option value={60}>60 Mintues</option>
                                                </select>
                                             </div>
                                          </div>
                                          <div className="col-lg-4">
                                             <div className="form-group">
                                                <label for="">Available Wallet time</label>
                                                <h4><a href="javascript:;">06 Hours 30 Mins</a></h4>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div> */}
                        <div className="book-appointment-box">
                          <h1>Add your details</h1>
                          <div className="book-app-box">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      SetQuestion(e.target.value);
                                    }}
                                    placeholder="Question to Ask ?"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="continue-booking-btn p-0 mb-4">
                          {/* <button data-bs-target="#scheduleAppointmentModal"  data-bs-dismiss="modal" data-bs-toggle="modal" className="btn" type="button"> Continue to Book Appointment </button> */}
                          <button
                            className="btn"
                            onClick={(e) => {
                              e.preventDefault();
                              // handlemodal();
                              getExpertChatCharges();
                              // fetchTime()
                            }}
                          >
                            Request For Chat
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        className="authentication-modal modal-dialog modal-dialog-centered modal-xl"
        isOpen={paymentModal}
        toggle={() => {
          setPaymentModal(false);
        }}
      >
        <ModalBody>
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setPaymentModal(false);
                  setMainLoader(false);
                }}
              ></button>
              <div className="auth-modal-wrp">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="auth-modal-content">
                      <div className="w-100">
                        <div className="book-appointment-box">
                          <h1>
                            You have insufficient balance to make this
                            appointment
                          </h1>
                          <div>
                            <span>
                              You have to pay <b> {amountToPay} OMR</b>
                            </span>
                          </div>
                        </div>
                        <div className="continue-booking-btns p-0 mt-4 d-flex">
                          <button
                            className="btn my-1 mx-1 btn-danger"
                            onClick={() => {
                              setPaymentModal(false);
                              setMainLoader(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn my-1 mx-1 btn-success"
                            onClick={() => {
                              addUserOneTimePayment();
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ExpProfile;
