import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import homeAction from "../../actions/home.action";
import { GoogleLogout } from "react-google-login";
import { AuthContext } from "../../context/AuthContext";

const Sidebar = (props) => {
  console.log("this is props", props.getProfilePic);
  const history = useNavigate();
  const [toggleButton, setToggleButton] = useState(false);
  const [profilePic, setProfilepic] = useState("");
  const {
    loggedInBorhanUserInfo,
    setLoggedInBorhanUserInfo,
    isLoggedIn,
    setIsLoggedIn,
  } = useContext(AuthContext);
  const CLIENT_ID =
    "192073990165-k8uk1edbbhb0lm03lqb7ikvf3ibqotr5.apps.googleusercontent.com";

  const location = useLocation();
  // setProfilepic(props.getProfilePic)

  useEffect(() => {
    console.log("this is useeffect", props.getProfilePic);
    if (props.getProfilePic) {
      setProfilepic(props.getProfilePic);
    } else {
      setProfilepic(loggedInBorhanUserInfo.profilePic);
    }
  }, [props.getProfilePic, profilePic]);
  console.log("this is userData", loggedInBorhanUserInfo);

  // const [loggedInBorhanUserInfo,setLoggedInBorhanUserInfo]=useState([]);
  //    const getBorhanUserDetails = async () => {
  //       // console.log(decodedToken,"hi its decoded");
  //       homeAction.getBorhanUserDetails((err,res)=>{
  //          if(err){
  //            console.log(err,"helllooo")
  //          }else{
  //          //   setGetCategories(res.data);
  //          //   console.log(res.data,"user details daata ");
  //            setLoggedInBorhanUserInfo(  res.data  );
  //          //   setGetProfilePic(res.data.profilePic)
  //          }
  //        });

  //   };
  useEffect(() => {
    // setProfilepic(props)
    // getBorhanUserDetails();
  }, []);
  const logout = () => {
    localStorage.removeItem("token");
    setIsLoggedIn(false);
    history("/");
    window.location.reload();
  };

  return (
    <>
      <div className="sidebar-toggle">
        <button
          className="btn sidebar-mb-toggle-btn"
          onClick={() => setToggleButton(!toggleButton)}
        >
          <img src="/assets/img/expand-sidebar.png" alt="" /> User Menu
        </button>
      </div>
      <div className={`sidebar-wrapper ${toggleButton ? "d-block" : ""}`}>
        <div className="flex-shrink-0">
          <div className="user-img-sidebar">
            {console.log("this is profile", profilePic)}
            <img
              src={`${profilePic === "" ? "" : profilePic}`}
              className="img img-fluid"
              alt=""
            />
            <h3>
              {loggedInBorhanUserInfo.firstName}{" "}
              {loggedInBorhanUserInfo.lastName}
            </h3>
          </div>
          <ul className="list-unstyled ps-0">
            <li
              className={`${
                location.pathname === "/userdashboard" ? "active" : ""
              }`}
            >
              <Link to="/userdashboard" className="">
                <img
                  src="/assets/img/profile-icon.png"
                  className="img img-fluid"
                  alt=""
                />{" "}
                My Profile
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/appointmentdetails" ? "active" : ""
              }`}
            >
              <Link to="/appointmentdetails" className="">
                <img
                  src="/assets/img/appointment-icon.png"
                  className="img img-fluid"
                  alt=""
                />{" "}
                Appointment details
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/favexperts" ? "active" : ""
              }`}
            >
              <Link to="/favexperts" className="">
                <img
                  src="/assets/img/favorite-icon.png"
                  className="img img-fluid"
                  alt=""
                />{" "}
                Favorite experts
              </Link>
            </li>
            <li
              className={`${location.pathname === "/wallet" ? "active" : ""}`}
            >
              <Link to="/wallet" className="">
                <img
                  src="/assets/img/wallet-icon.png"
                  className="img img-fluid"
                  alt=""
                />{" "}
                Wallet
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/ChatSupport" ? "active" : ""
              }`}
            >
              <Link to="/ChatSupport" className="">
                <img
                  src="/assets/img/chat-icon.png"
                  className="img img-fluid"
                  alt=""
                />{" "}
                Chat Support
              </Link>
            </li>
            <li className="">
              <Link to="/savedCards" className="">
                <img
                  src="/assets/img/saved-cards-icon.png"
                  className="img img-fluid"
                  alt=""
                />{" "}
                Saved cards
              </Link>
            </li>
            <li
              className={`${
                location.pathname === "/managemembership" ? "active" : ""
              }`}
            >
              <Link to="/managemembership" className="">
                <img
                  src="/assets/img/vuesax-linear-chart-white.png"
                  className="img img-fluid"
                  alt=""
                />{" "}
                Manage Membership
              </Link>
            </li>
            <li className="">
              <a
                href="/"
                class=""
                onClick={(e) => {
                  e.preventDefault();
                  logout();
                }}
              >
                <img
                  src="/assets/img/logout-icon.png"
                  class="img img-fluid"
                  alt=""
                />{" "}
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
