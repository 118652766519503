import Agent from "./superAgent";
import config from "../config/configg";
import { ServerError } from "../utils/helpers";
const BACKEND_URL = config.BACKEND_URL;

function fetchAllExpertsOnline(cb) {
  Agent.fire("get", `${BACKEND_URL}/website/getOnlineExperts`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}

function getBorhanUserDetails(cb) {
  Agent.fire("get", `${BACKEND_URL}/website/getBorhanUserDetails`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}

const editBorhanUserDetails = (payload, cb) => {
  Agent.fire("put", `${BACKEND_URL}/website/editBorhanUserDetails`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
};

function fetchSearchedPracticeArea(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/getPracticeAreaDataSearched`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
function fetchTestimonies(cb) {
  Agent.fire("get", `${BACKEND_URL}/website/getTestimonies`).end((err, res) => {
    var error =
      err || res.error
        ? ServerError(res)
        : res.body && res.body.error
        ? ServerError(res)
        : null;
    if (typeof cb === "function") return cb(error, res && res.body);
  });
}

function newsletterSubscribed(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/subscribeNewsletter`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function addUserRequest(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/addUserRequest`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}
//payment gateway function started
function getAllSubscriptionTypes(cb) {
  Agent.fire("get", `${BACKEND_URL}/website/getAllSubscriptionTypes`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}

function addUserSubscription(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/addUserSubscription`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function updateUserSubscription(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/updateUserSubscription`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function getExpertPriceDetails(id, cb) {
  Agent.fire(
    "get",
    `${BACKEND_URL}/website/getExpertPriceDetails?expertId=${id}`
  ).end((err, res) => {
    var error =
      err || res.error
        ? ServerError(res)
        : res.body && res.body.error
        ? ServerError(res)
        : null;
    if (typeof cb === "function") return cb(error, res && res.body);
  });
}

function getExpertPricingDetailsAccordingToCallType(payload, cb) {
  Agent.fire(
    "post",
    `${BACKEND_URL}/website/getExpertPricingDetailsAccordingToCallType`
  )
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function getAmountCharged(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/getAmountCharged`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function amountToPayForAppointmentBooking(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/amountToPayForAppointmentBooking`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function addUserOneTimePayment(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/addUserOneTimePayment`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function updateUserOneTimePayment(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/updateUserOneTimePayment`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function getUserWalletDetails(skip, limit, cb) {
  Agent.fire(
    "get",
    `${BACKEND_URL}/website/getUserWalletDetails?skip=${skip}&limit=${limit}`
  ).end((err, res) => {
    var error =
      err || res.error
        ? ServerError(res)
        : res.body && res.body.error
        ? ServerError(res)
        : null;
    if (typeof cb === "function") return cb(error, res && res.body);
  });
}

function getUserPaymentMethodDetails(cb) {
  Agent.fire("get", `${BACKEND_URL}/website/getUserPaymentMethodDetails`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}

function getUserAllActivePlans(cb) {
  Agent.fire("get", `${BACKEND_URL}/website/getUserAllActivePlans`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}

function getExpertChatCharges(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/getExpertChatCharges`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function updateUserChatRoom(payload, cb) {
  Agent.fire("post", `${BACKEND_URL}/website/updateUserChatRoom`)
    .send(payload)
    .end((err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    });
}

function getAllPremiumExperts(cb) {
  Agent.fire("get", `${BACKEND_URL}/website/getAllPremiumExperts`).end(
    (err, res) => {
      var error =
        err || res.error
          ? ServerError(res)
          : res.body && res.body.error
          ? ServerError(res)
          : null;
      if (typeof cb === "function") return cb(error, res && res.body);
    }
  );
}

export default {
  fetchAllExpertsOnline,
  getBorhanUserDetails,
  editBorhanUserDetails,
  fetchSearchedPracticeArea,
  fetchTestimonies,
  newsletterSubscribed,
  addUserRequest,
  getAllSubscriptionTypes,
  addUserSubscription,
  updateUserSubscription,
  getExpertPriceDetails,
  getExpertPricingDetailsAccordingToCallType,
  getAmountCharged,
  amountToPayForAppointmentBooking,
  addUserOneTimePayment,
  updateUserOneTimePayment,
  getUserWalletDetails,
  getUserPaymentMethodDetails,
  getUserAllActivePlans,
  getExpertChatCharges,
  updateUserChatRoom,
  getAllPremiumExperts,
};
