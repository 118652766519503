import {React,useEffect,useState} from 'react'

import {Link,useNavigate} from 'react-router-dom'
// import { Toast } from 'reactstrap';
import expertlistingAction from '../../actions/expertlisting.action';
import Sidebar from './Sidebaruser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import categoriesAction from '../../actions/categories.action';
import { TailSpin } from 'react-loader-spinner';

const FavExp =()=>{
   const [mainLoader,setMainLoader]=useState(true);
   const history=useNavigate();
   const [FavExperts,setFavExperts]=useState([]);
   const [getPracticeArea,setGetPracticeArea]=useState([]);
   const getFavouriteExperts= ()=>{
       setMainLoader(true);
      expertlistingAction.getUsersFavoriteExperts( (err, res) => {
         if (err) {
         //   alert("")err
         toast('something went wrong');
         } else {
           
        setFavExperts(res.data);
      //   console.log('data of fav',res.data)
          }
          setMainLoader(false);
       })
   }
   useEffect(() => {
      if(localStorage.getItem('token'))
      {
         getFavouriteExperts();
         fetchAllPracticeArea();
      }
      else{
       history('/')
      }
   }, [])
   const fetchAllPracticeArea = async () => {
      categoriesAction.getParticularPracticeAreaCateories((err, res) => {
        if (err) {
  
        } else {
          console.log(res.data, " daata ");
          setGetPracticeArea(res.data);
        }
      });
  
    };
    const modals=(obj,getPreaticeArea)=>{
      console.log("this is obj" , obj)
      console.log("this is ppp",getPracticeArea)
      let expertId =obj&& obj.expertId && obj.expertId._id;
      let category =obj&& obj.expertId && obj.expertId.category;
     
      let preaticeArea =obj&& obj.expertId && obj.expertId.practiceArea[0];
      let practiceArea;
      let categoryName;
      getPreaticeArea.map((ele)=>{
             if(preaticeArea==ele._id || category==ele.categoryId._id)
             {
               let practice=ele.name;
               practiceArea=practice;
               categoryName=ele.categoryId.name
             }
            
      })

      console.log("this is ppr",practiceArea,categoryName)
      history(`/expprofile/${expertId}/${categoryName}/${practiceArea}`)
    }
  
    return(
        <>
        {mainLoader ? (
          <div className="custm-loader">
            <TailSpin color="#000" height={200} width={200}  />
          </div>
         ) : null}
    
              <section className="admin-wrapper">
       <Sidebar/>
         <div className="admin-content-wrapper">
            <div className="row">

               {FavExperts.map((obj,index)=>{
                  
                  return(
                     <div className="col-lg-3">
                  <div className="exp-listing-box">
                           <div className="exp-listing-img">
                              {/* {console.log("this is exptss", obj.expertUserId.profilePic)} */}
                              <img
                                 src={`${obj.expertUserId.profilePic === ""
                                    ? "/assets/img/expert-thumb.png"
                                    : obj.expertUserId.profilePic
                                    }`}
                                 className="img img-fluid"
                                 alt=""
                              />
                              {/* <img src="/assets/img/expert-thumb.png" className="img img-fluid" alt="" /> */}
                     </div>
                     <div className="exp-listing-content">
                        <h1>{obj?.expertUserId?.firstName}</h1>
                        <h4>{getPracticeArea.length>0&&getPracticeArea.map((object,index)=>{
                           if(object?._id===obj?.expertId?.practiceArea[0])
                           {
                              return(<>{object.name}</>)
                           }
                        })}</h4>
                        {/* <Link to={`/expprofile/${obj.expertId._id}`}> */}
                           <button className="btn mt-2" type="button" onClick={()=>modals(obj,getPracticeArea)}>Book Appointment</button>
                           {/* </Link> */}

                        {/* <Link to={`/expprofile/${obj.expertId._id}`}><button className="btn mt-2" type="button">Book Appointment</button></Link> */}
                     </div>
                  </div>
               </div>
                  )
               })
               }
               


            </div>
         </div>
      </section>
      <ToastContainer/>
        </>
    );
}

export default FavExp